import axios from 'axios';
import { HOST } from './config';
import FindAllPatientsResponse from '../patient/FindAllPatientsResponse';

type PatientData = {
  lastUpdated: string;
  name: string;
  dob?: string;
};

export default class PatientsApi {
  async addPatient(
    userId: string,
    patientData: PatientData,
    accessToken: string
  ) {
    try {
      const response = await axios.post(
        `${HOST}/audioapi/patient?userId=${encodeURIComponent(userId)}`,
        patientData,
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error while adding patient:', error);
      throw error;
    }
  }

  async getAll(
    userId: string,
    accessToken: string,
    page = 1,
    keyword = ''
  ): Promise<FindAllPatientsResponse> {
    const response = await axios.get(
      `${HOST}/audioapi/patients?userId=${encodeURIComponent(
        userId
      )}&page=${page}${keyword ? `&keyword=${keyword}` : ''}`,
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }
}
