import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Separator } from '../ui/Separator';
import { Button } from '../ui/Button';
import { useNavigate } from 'react-router-dom';
import RoutingPath from '../../routing/RoutingPath';
import { useUserData } from '../../context/UserContextProvider';
import Page from '../Page/Page';
import {
  AlertDialog,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../ui/AlertDialog/AlertDialogParts';
import { AlertDialogContent } from '../ui/AlertDialog/AlertDialogContent';
import { AlertDialogAction } from '../ui/AlertDialog/AlertDialogAction';
import { useState } from 'react';
import { AlertDialogCancel } from '../ui/AlertDialog/AlertDialogCancel';
import Loading from '../Loading/Loading';
import { useToast } from '../ui/Toast/UseToast';
import { useAuth0 } from '@auth0/auth0-react';
import UserApi from '../../api/UserApi';

export default function AccountPage() {
  const { userData } = useUserData();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { toast } = useToast();
  const { user, getAccessTokenSilently, logout } = useAuth0();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    try {
      if (!user?.sub || !userData?._id || !userData?.email) {
        throw new Error('No user id');
      }
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      if (!token) {
        throw new Error('No access token');
      }
      await new UserApi().delete(userData._id, user.sub, userData.email, token);
      toast({
        title: 'Success',
        description: 'Account deleted',
      });
      await logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    } catch (errors) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Please try again in few moments or contact support',
      });
      console.error(errors);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  const sessionsLeft =
    (userData?.sessionsLeft || 0) > 12 ? 'unlimited' : userData?.sessionsLeft;
  let plan = '-';
  switch (userData?.plan) {
    case 'free':
      plan = 'Free';
      break;
    case 'pro':
      plan = 'Solo Pro';
      break;
    case 'enterprise':
      plan = 'Enterprise';
      break;
  }

  return (
    <Page>
      <div className="size-full">
        <h2 className="text-2xl font-bold">{t('account.title')}</h2>
        <p className="text-sm text-gray-500">{t('account.description')}</p>
        <Separator className="my-5" />
        <div className=" grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 mb-5">
          <div>
            <p className="text-gray-500">{t('account.fullName')}</p>
            <p className="text-lg font-bold">{userData?.name || '-'}</p>
          </div>
          <div>
            <p className="text-gray-500">{t('account.email')}</p>
            <p className="text-lg font-bold overflow-ellipsis">
              {userData?.email || '-'}
            </p>
          </div>
          <div>
            <p className="text-gray-500">{t('payment.plan')}</p>
            <p className="text-lg font-bold">{plan}</p>
          </div>
          <div>
            <p className="text-gray-500">{t('payment.sessionsLeft')}</p>
            <p className="text-lg font-bold">{sessionsLeft}</p>
          </div>
        </div>
        <Button
          className="w-full mb-3"
          onClick={() => navigate(RoutingPath.EDIT_ACCOUNT)}
        >
          {t('account.editTitle')}
        </Button>
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button className="w-full" type="button" variant="outline">
              {t('account.delete')}
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>{t('account.deletingTitle')}</AlertDialogTitle>
              <AlertDialogDescription>
                {t('account.deletingDescription')}
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={() => handleDelete()}>
                Delete account
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </Page>
  );
}
