import React from 'react';
import { Tabs } from '../ui/Tabs/Tabs';
import { TabsList } from '../ui/Tabs/TabsList';
import { TabsTrigger } from '../ui/Tabs/TabsTrigger';
import { TabsContent } from '../ui/Tabs/TabsContent';
import Transcription from './Transcription';
import SOAPNotesList from './SOAPNotesList';
import {
  DeepgramResponse,
  PreprocessingDialogMessage,
  SOAPNote,
} from './RenderNote';
import { Button } from '../ui/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BookmarkPlus } from 'lucide-react';
import CDSS from './CDSS';
import { Cdss } from '../../result/Cdss';

interface RecordingProcessProps {
  data?: DeepgramResponse;
  onTranscriptionSave: (dialog: PreprocessingDialogMessage[]) => void;
  onNoteSave: (dialog: SOAPNote) => void;
  onCdssSave: (dialog: Cdss) => void;
  onReset: () => void;
}

const Result = ({
  data,
  onTranscriptionSave,
  onNoteSave,
  onCdssSave,
  onReset,
}: RecordingProcessProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <div className="mb-3 flex w-full justify-end">
        <Button
          icon={<BookmarkPlus />}
          iconSize="text-xl"
          onClick={() => onReset()}
        >
          {t('note.createNew')}
        </Button>
      </div>
      <Tabs defaultValue="transcription">
        <TabsList className="mb-5 flex h-auto flex-wrap items-center justify-start space-y-1 bg-primary-background">
          <TabsTrigger value="transcription">Transcription</TabsTrigger>
          <TabsTrigger value="soapNotes">SOAP Note</TabsTrigger>
          <TabsTrigger value="cdss">
            Clinical decision support system
          </TabsTrigger>
        </TabsList>
        <TabsContent value="transcription">
          <Transcription
            id={data?._id}
            dialog={data?.preprocessingDialog}
            speaker0={data?.speaker0}
            speaker1={data?.speaker1}
            onClick={onTranscriptionSave}
          />
        </TabsContent>
        <TabsContent value="soapNotes">
          <SOAPNotesList
            id={data?._id}
            note={data?.note}
            onClick={onNoteSave}
          />
        </TabsContent>
        <TabsContent value="cdss">
          {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
          <CDSS cdss={data?.cdss} onClick={onCdssSave} />
        </TabsContent>
      </Tabs>
    </>
  );
};

export default Result;
