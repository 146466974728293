import React, { useLayoutEffect } from 'react';
import { Switch } from '../ui/Switch';
import { CardTitle } from '../ui/Card/CardTitle';
import { CardDescription } from '../ui/Card/CardDescription';
import { FieldValues } from 'react-hook-form';

const RegisterSwitch: React.FC<FieldValues> = ({
  title,
  description,
  register,
  errors,
  schema,
  schemaTitle,
  setValue,
}) => {
  useLayoutEffect(() => {
    setValue(schemaTitle, false);
  }, [setValue, schemaTitle]);

  const handleChangeValue = (value: boolean) => {
    setValue(schemaTitle, value);
  };
  return (
    <div>
      <div className="mt-4 flex justify-between space-x-2">
        <div>
          <CardTitle className="text-base font-bold">{title}</CardTitle>
          <CardDescription className="text-sm">{description}</CardDescription>
        </div>
        <div className="flex items-center">
          <Switch
            {...register(schemaTitle, {
              validate: (value: boolean) => {
                schema.shape[schemaTitle].parse(value);
              },
            })}
            onCheckedChange={handleChangeValue}
          />
        </div>
      </div>
      {errors[schemaTitle] && (
        <p className="w-full text-red-500">{errors[schemaTitle].message}</p>
      )}
    </div>
  );
};

export default RegisterSwitch;
