import React from 'react';
import { Bars } from 'react-loader-spinner';

interface LoadingProps {
  customHeightClass?: string;
  viewportHeight?: boolean;
}

export default function Loading({
  customHeightClass,
  viewportHeight,
}: LoadingProps) {
  const heightClass = customHeightClass || (viewportHeight ? 'h-dvh' : 'h-5/6');
  return (
    <div
      className={`w-full mx-auto flex items-center justify-center ${heightClass}`}
    >
      <Bars
        height="80"
        width="80"
        color="#1d6363"
        ariaLabel="bars-loading"
        wrapperClass=""
        visible={true}
      />
    </div>
  );
}
