import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Card } from '../ui/Card/Card';
import { CardHeader } from '../ui/Card/CardHeader';
import { CardTitle } from '../ui/Card/CardTitle';
import { CardContent } from '../ui/Card/CardContent';
import { CardFooter } from '../ui/Card/CardFooter';
import Patient from '../../patient/Patient';
import { Button } from '../ui/Button';
import { useTranslation } from 'react-i18next';

const PatientProfile = () => {
  const { patientId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const patient = location.state as Patient;

  if (!patientId || !Object.keys(patient).length) {
    return <div>No patientId provided.</div>;
  }

  return (
    <Card className="mx-auto max-w-[800px] w-full">
      <CardHeader>
        <CardTitle className="text-center text-lg font-normal">
          Patient Profile for ID:
          <b className="ml-1">
            {patient.name}
            {patient.dob.split('-')}
          </b>
        </CardTitle>
      </CardHeader>
      <CardContent className="mx-auto w-[75%]">
        <p>
          Name: <b>{patient.name}</b>
        </p>
        <p className="my-2">
          Date of birth: <b>{patient.dob}</b>
        </p>
        <p>
          Last updated: <b>{patient.lastUpdated}</b>
        </p>
      </CardContent>
      <CardFooter>
        <Button
          className="ml-auto"
          onClick={() => {
            navigate('/patients');
          }}
        >
          {t('button.comeBackPatients')}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default PatientProfile;
