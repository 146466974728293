import React, { ReactNode } from 'react';

import { TabsList } from '../ui/Tabs/TabsList';
import { TabsTrigger } from '../ui/Tabs/TabsTrigger';
import { TabsContent } from '../ui/Tabs/TabsContent';
import LoginForm from '../Login/LoginForm';
import RegisterForm from '../Register/RegisterForm';
import { Tabs } from '../ui/Tabs/Tabs';

export default function Login() {
  return (
    <div className="block">
      <div className="bg-background">
        <div className="container min-h-lvh">
          <div className="mx-auto max-w-[500px] w-full flex min-h-lvh items-center justify-center pb-3 pt-3">
            <Tabs defaultValue="loginForm" className="max-w-[500px] w-full">
              <TabsList className="grid mx-auto max-w-[500px] w-full grid-cols-2 bg-primary-background">
                <TabsTrigger value="loginForm">Sign in</TabsTrigger>
                <TabsTrigger value="registerForm">Sign up</TabsTrigger>
              </TabsList>
              <TabsContent value="loginForm">
                <LoginForm />
              </TabsContent>
              <TabsContent value="registerForm">
                <RegisterForm />
              </TabsContent>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}
