import React from 'react';
import { Button } from '../ui/Button';
import { Card } from '../ui/Card/Card';
import { useTranslation } from 'react-i18next';
import { CardHeader } from '../ui/Card/CardHeader';
import { CardTitle } from '../ui/Card/CardTitle';
import { CardDescription } from '../ui/Card/CardDescription';
import { CardContent } from '../ui/Card/CardContent';
import { CardFooter } from '../ui/Card/CardFooter';
import {
  SubmitErrorHandler,
  SubmitHandler,
  UseFormSetValue,
  useForm,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import RegisterPolicy from './RegisterPolicy';
import RegisterSwitch from './RegisterSwitch';
import axios from 'axios';
import { HOST } from '../../api/config';
import RoutingPath from '../../routing/RoutingPath';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../ui/Toast/UseToast';
import { ToastAction } from '../ui/Toast/ToastAction';
import { useAuth0 } from '@auth0/auth0-react';

type RegisterFormFields = {
  email: string;
  password: string;
  profession: boolean;
  terms_service: boolean;
  setValue: UseFormSetValue<RegisterFormFields>;
};

const schema = z.object({
  email: z.string().email({ message: 'Invalid email address' }),
  password: z
    .string()
    .min(8, { message: 'Password must have at least 8 characters' }),
  profession: z
    .boolean()
    .default(false)
    .refine((value) => value == true, {
      message: 'You need to confirm it',
    }),
  terms_service: z
    .boolean()
    .default(false)
    .refine((value) => value == true, {
      message: 'Terms of service must be accepted',
    }),
});

const RegisterForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { toast } = useToast();
  const { loginWithRedirect } = useAuth0();

  const handleRegister = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/',
      },
      authorizationParams: {
        prompt: 'login',
        screen_hint: 'signup',
      },
    });
  };

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<RegisterFormFields>({
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<RegisterFormFields> = async (data) => {
    try {
      const response = await axios.post(`${HOST}/audioapi/user`, {
        username: data.email,
        password: data.password,
      });
      toast({
        title: 'Your account has been created.',
        description: 'You can log in using your data',
        action: (
          <ToastAction
            altText="Log in"
            onClick={() => {
              navigate(RoutingPath.LOGIN);
            }}
          >
            Log in
          </ToastAction>
        ),
      });
    } catch (err) {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'There was a problem with your request.',
        action: <ToastAction altText="Try again">Try again</ToastAction>,
      });
    }
  };

  const onError: SubmitErrorHandler<RegisterFormFields> = (errors) => {
    console.error(errors);
  };

  return (
    <Card className="mx-auto max-w-[500px] w-full">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <CardHeader>
          <CardTitle className="text-base font-bold">
            {t('account.createAccount')}
          </CardTitle>
          <CardDescription className="text-sm ">
            {t('account.createDescription')}
          </CardDescription>
        </CardHeader>
        <CardContent>
          {/*<div className="grid w-full items-center gap-4">*/}
          {/*</div>*/}
          {/*<Separator className="my-5" />*/}
          <RegisterPolicy />
          <RegisterSwitch
            schemaTitle="profession"
            title={t('account.profession')}
            description={t('account.professionConfirm')}
            register={register}
            errors={errors}
            setValue={setValue}
            schema={schema}
          />
          <RegisterSwitch
            schemaTitle="terms_service"
            title={t('account.terms')}
            description={t('account.termsConfirm')}
            register={register}
            errors={errors}
            setValue={setValue}
            schema={schema}
          />
        </CardContent>
        <CardFooter className="flex justify-between">
          <Button
            onClick={handleRegister}
            className="w-full bg-primary"
            type="submit"
          >
            {t('account.createAccount')}
          </Button>
        </CardFooter>
      </form>
    </Card>
  );
};
export default RegisterForm;
