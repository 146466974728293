import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardTitle } from '../ui/Card/CardTitle';
import { CardDescription } from '../ui/Card/CardDescription';

const RegisterPolicy = () => {
  const { t } = useTranslation();

  return (
    <div>
      <CardTitle className="text-base font-bold">
        {t('account.dataPrivacy')}
      </CardTitle>
      <CardDescription className="mt-2 text-sm leading-5 ">
        {t('account.dataPrivacyMessage')}
        <a className="ml-1 underline">{t('account.privacyPolicy')}</a>.
      </CardDescription>
    </div>
  );
};

export default RegisterPolicy;
