import React, { useState } from 'react';
import { Button } from '../ui/Button';
import { useTranslation } from 'react-i18next';
import { CardTitle } from '../ui/Card/CardTitle';
import { CardDescription } from '../ui/Card/CardDescription';
import { Separator } from '../ui/Separator';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import NotePatient, { PatientForm, SelectFieldOption } from './NotePatient';
import { useQuery } from '@tanstack/react-query';
import PatientsApi from '../../api/PatientsApi';
import Loading from '../Loading/Loading';
import { RiVoiceprintLine } from 'react-icons/ri';
import { InputWithLabel } from '../ReusableElements/InputWithLabel';
import Patient from '../../patient/Patient';
import { useNavigate } from 'react-router-dom';
import { useUserData } from '../../context/UserContextProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast } from '../ui/Toast/UseToast';
import FindAllPatientsResponse from '../../patient/FindAllPatientsResponse';

export type CrateNoteForm = {
  noteName: string;
};

export type CreateNoteFormData = {
  noteName: string;
  patient: PatientForm;
};

const schema = z.object({
  noteName: z.string().min(1, {
    message: 'Name must have at least one character',
  }),
});

interface CreateNoteProps {
  onClick: (data: CreateNoteFormData) => void;
}

const PATIENTS: Patient[] = [
  {
    _id: '1',
    userId: '1',
    name: 'Szymon',
    dob: 'dob',
    lastUpdated: 'date',
  },
];

export default function CreateNote({ onClick }: CreateNoteProps) {
  const { userId } = useUserData();
  const { user, getAccessTokenSilently } = useAuth0();
  const { toast } = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [patient, setPatient] = useState<PatientForm>({});

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    watch,
    getValues,
    control,
  } = useForm<CrateNoteForm>({
    resolver: zodResolver(schema),
  });

  const { data, error, isLoading, refetch } = useQuery<
    FindAllPatientsResponse | undefined
  >({
    queryKey: ['patients'],
    queryFn: async () => {
      try {
        if (!user?.sub) {
          throw new Error('No user id');
        }
        const token = await getAccessTokenSilently();
        if (!token) {
          throw new Error('No access token');
        }
        return new PatientsApi().getAll(user.sub, token, 1);
      } catch (e) {
        toast({
          variant: 'destructive',
          title: 'Could not find patients',
          description: 'Please try again in few moments or contact support',
        });
        console.log(e);
      }
    },
    retry: false,
  });

  const onSubmit: SubmitHandler<CrateNoteForm> = (data) => {
    try {
      onClick({ ...data, patient });
    } catch (errors) {
      console.error(errors);
    }
  };

  const handlePatientChange = (value: PatientForm) => {
    setPatient(value);
  };

  const handleAddPatient = () => {
    refetch();
  };

  if (isLoading) {
    return <Loading />;
  }

  const patients: SelectFieldOption[] = data
    ? data.results.map(({ name, _id }) => ({
        value: _id,
        name,
      }))
    : [];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardTitle>{t('note.createNotes')}</CardTitle>
      <CardDescription className="mt-1">{t('note.noteInfo')}</CardDescription>
      <Separator className="my-5" />
      <InputWithLabel
        label={t('note.noteName')}
        id="name"
        placeholder="Name"
        register={register}
        registerName="noteName"
        errors={errors}
      />
      <NotePatient
        options={patients}
        inputLabel={t('note.patient')}
        onPatientChange={handlePatientChange}
        onAddedPatient={handleAddPatient}
      />
      <Button
        className=" mt-5 w-full"
        icon={<RiVoiceprintLine />}
        iconSize="text-xl"
        type="submit"
      >
        {t('note.recordStart')}
      </Button>
    </form>
  );
}
