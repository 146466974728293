import axios from 'axios';
import { HOST } from './config';
import GetUserResponse from '../user/GetUserResponse';
import { Language } from '../user/UserData';

export default class UserApi {
  async get(id: string, email: string, accessToken: string) {
    const response = await axios.get<GetUserResponse>(
      `${HOST}/audioapi/user?userId=${encodeURIComponent(
        id
      )}&email=${encodeURIComponent(email)}`,
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.user;
  }

  updateLanguage(id: string, lang: Language, accessToken: string) {
    const url = `${HOST}/audioapi/lang`;
    const body = JSON.stringify({ userId: id, lang });

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: body,
    };

    return fetch(url, requestOptions);
    return axios.post(
      `${HOST}/audioapi/lang?userId=${encodeURIComponent(id)}&lang=${lang}`,
      { data: '' },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  updateProfile(id: string, name: string, accessToken: string) {
    return axios.post(
      `${HOST}/audioapi/user-profile?userId=${encodeURIComponent(id)}`,
      { name },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  delete(id: string, userId: string, email: string, accessToken: string) {
    return axios.post(
      `${HOST}/audioapi/delete-user?userId=${encodeURIComponent(userId)}`,
      { id, userId, email },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
}
