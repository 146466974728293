import React, { useEffect, useState } from 'react';
import { Button } from '../ui/Button';
import { useTranslation } from 'react-i18next';
import { CardTitle } from '../ui/Card/CardTitle';
import { CardDescription } from '../ui/Card/CardDescription';
import { Separator } from '../ui/Separator';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { RiVoiceprintLine } from 'react-icons/ri';
import { LuFileEdit, LuPenLine } from 'react-icons/lu';
import { LuSave } from 'react-icons/lu';
import { Buttons } from '../ReusableElements/Buttons';
import { MdOutlineCancel } from 'react-icons/md';
import { FaRegCopy } from 'react-icons/fa';
import ResizableTextareaField from '../Form/ResizableTextareaField';
import { toast } from '../ui/Toast/UseToast';
import { format } from 'date-fns';
import { Cdss, CdssNextSteps } from '../../result/Cdss';

type FormValues = {
  differentialDiagnosis?: string[];
  workup?: string;
  nextSteps?: CdssNextSteps[];
  references?: string[];
};

interface CDSSProps {
  cdss?: Cdss;
  updatedAt?: string;
  onClick: (form: Cdss) => void;
}

type CDSSKey = 'differentialDiagnosis' | 'workup' | 'nextSteps' | 'references';

const CDSS = ({ cdss, onClick, updatedAt }: CDSSProps) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState<Cdss>({
    differentialDiagnosis: [],
    workup: '',
    nextSteps: [],
    references: [],
  });

  const [differentialDiagnosisContent, setDifferentialDiagnosisContent] =
    useState<string[]>(cdss?.differentialDiagnosis || []);
  const [workupContent, setWorkupContent] = useState<string>(
    cdss?.workup || ''
  );
  const [nextStepsContent, setNextStepsContent] = useState<CdssNextSteps[]>(
    cdss?.nextSteps || []
  );
  const [referencesContent, setReferencesContent] = useState<string[]>(
    cdss?.references || []
  );

  const {
    handleSubmit,
    register,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>({
    // resolver: zodResolver(schema),
  });

  useEffect(() => {
    setDifferentialDiagnosisContent(cdss?.differentialDiagnosis || []);
    setWorkupContent(cdss?.workup || '');
    setNextStepsContent(cdss?.nextSteps || []);
    setReferencesContent(cdss?.references || []);
  }, [cdss]);

  const handleEditClick = (type: CDSSKey) => {
    if (type === 'differentialDiagnosis') {
      clearErrors('differentialDiagnosis');
      setEditing({
        ...editing,
        differentialDiagnosis: differentialDiagnosisContent,
      });
    } else if (type === 'workup') {
      clearErrors('workup');
      setEditing({ ...editing, workup: workupContent });
    } else if (type === 'nextSteps') {
      clearErrors('nextSteps');
      setEditing({ ...editing, nextSteps: nextStepsContent });
    } else if (type === 'references') {
      clearErrors('references');
      setEditing({ ...editing, references: referencesContent });
    }
  };

  const handleNoteSave = () => {
    onClick({
      differentialDiagnosis: differentialDiagnosisContent,
      workup: workupContent,
      nextSteps: nextStepsContent,
      references: referencesContent,
    });
  };

  const handleSaveEditClick = (type: CDSSKey) => {
    if (type === 'differentialDiagnosis') {
      setDifferentialDiagnosisContent(editing.differentialDiagnosis || []);
    } else if (type === 'workup') {
      setWorkupContent(editing.workup || '');
    } else if (type === 'nextSteps') {
      setNextStepsContent(editing.nextSteps || []);
    } else if (type === 'references') {
      setReferencesContent(editing.references || []);
    }
    setEditing({
      differentialDiagnosis: [],
      workup: '',
      nextSteps: [],
      references: [],
    });
    handleNoteSave();
  };

  const handleCancelEditClick = () => {
    setEditing({
      differentialDiagnosis: [],
      workup: '',
      nextSteps: [],
      references: [],
    });
  };

  const copyText = (text: string) => {
    const inputElement = document.createElement('textarea');
    inputElement.value = text;
    document.body.appendChild(inputElement);
    inputElement.select();
    document.execCommand('copy');
    document.body.removeChild(inputElement);
  };

  const handleCopyClick = (type: CDSSKey) => {
    let text = '';
    if (type === 'differentialDiagnosis') {
      text = differentialDiagnosisContent
        .map((name, index) => `${index + 1}. ${name}\n`)
        .join('');
    } else if (type === 'workup') {
      text = workupContent;
    } else if (type === 'nextSteps') {
      text = nextStepsContent
        .map(
          ({ name, description }, index) =>
            `${index + 1}. ${name}\n${description}\n`
        )
        .join('');
    } else if (type === 'references') {
      text = referencesContent
        .map((reference, index) => `${index + 1}. ${reference}\n`)
        .join('');
    }
    copyText(text);

    toast({
      title: 'Text copied!',
    });
  };

  const copyAllFields = () => {
    const differentialDiagnosis = differentialDiagnosisContent.map(
      (name, index) => `${index + 1}. ${name}\n`
    );
    const nextSteps = nextStepsContent.map(
      ({ name, description }, index) =>
        `${index + 1}. ${name}\n${description}\n`
    );
    const references = referencesContent.map((reference) => `${reference}\n`);
    const allContent = `Differential diagnosis:\n${differentialDiagnosis}\n
    Workup:\n${workupContent}\n
    Next steps in the management and follow-up:\n${nextSteps}\n
    References:\n${references}`;
    copyText(allContent);

    toast({
      title: 'All fields copied!',
    });
  };

  const handleChangeName = (text: string, index: number, type: CDSSKey) => {
    if (type === 'differentialDiagnosis') {
      const newList = editing.differentialDiagnosis;
      if (newList && newList[index]) {
        newList[index] = text;
      }
      setEditing({
        ...editing,
        differentialDiagnosis: newList,
      });
    } else if (type === 'nextSteps') {
      const newList = editing.nextSteps;
      if (newList && newList[index]) {
        newList[index].name = text;
      }
      setEditing({
        ...editing,
        nextSteps: newList,
      });
    }
  };

  const handleChangeDescription = (
    text: string,
    index: number,
    type: CDSSKey
  ) => {
    if (type === 'nextSteps') {
      const newList = editing.nextSteps;
      if (newList && newList[index]) {
        newList[index].description = text;
      }
      setEditing({
        ...editing,
        nextSteps: newList,
      });
    }
  };

  const handleChangeWorkup = (text: string) => {
    setEditing({
      ...editing,
      workup: text,
    });
  };

  const hasDifferentialDiagnosis = editing.differentialDiagnosis?.length;
  const hasWorkup = editing.workup?.length;
  const hasNextSteps = editing.nextSteps?.length;
  const hasReferences = editing.references?.length;

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <CardTitle>Clinical decision support system</CardTitle>
        <CardDescription className="mt-1">
          Click at text to edit and click save button to save change in CDSS
        </CardDescription>
        {updatedAt ? (
          <CardDescription className="mt-1">
            Last updated: {format(new Date(updatedAt), 'dd-MM-yyyy HH:mm:ss')}
          </CardDescription>
        ) : null}
        <Separator className="my-5" />
        <div className="mb-3">
          <h3 className="font-bold">Differential diagnosis</h3>
          {editing.differentialDiagnosis?.length ? (
            <>
              {editing.differentialDiagnosis.map((name, index) => (
                <>
                  <p className="mb-1 text-label-primary">Name</p>
                  <ResizableTextareaField
                    value={name}
                    onChange={(text) => {
                      handleChangeName(text, index, 'differentialDiagnosis');
                    }}
                  />
                  <Buttons
                    onSaveClick={() =>
                      handleSaveEditClick('differentialDiagnosis')
                    }
                    onCancelClick={() => handleCancelEditClick()}
                    type="button"
                    save="Save"
                    cancel="Cancel"
                    iconCancel={<MdOutlineCancel />}
                    iconSave={<LuSave />}
                  />
                </>
              ))}
            </>
          ) : (
            <>
              {differentialDiagnosisContent?.map((name, index) => (
                <div key={`differential-diagnosis-${index}`}>
                  <p className="mb-1 text-label-primary">{`${
                    index + 1
                  }. ${name}`}</p>
                </div>
              ))}
              <Buttons
                edit="Edit"
                editName={t('button.edit')}
                type="button"
                copy="Copy"
                copyName={t('button.copy')}
                onCopyClick={() => handleCopyClick('differentialDiagnosis')}
                onEditClick={() => handleEditClick('differentialDiagnosis')}
                iconCopy={<FaRegCopy />}
                iconEdit={<LuFileEdit />}
              />
            </>
          )}
        </div>
        <div className="mb-3">
          <h3 className="font-bold">Workup</h3>
          {editing.workup?.length ? (
            <>
              <ResizableTextareaField
                value={editing.workup}
                onChange={handleChangeWorkup}
              />
              <Buttons
                onSaveClick={() => handleSaveEditClick('workup')}
                onCancelClick={() => handleCancelEditClick()}
                type="button"
                save="Save"
                cancel="Cancel"
                iconCancel={<MdOutlineCancel />}
                iconSave={<LuSave />}
              />
            </>
          ) : (
            <>
              <p className="mb-1 text-label-secondary">{workupContent}</p>
              <Buttons
                edit="Edit"
                editName={t('button.edit')}
                type="button"
                copy="Copy"
                copyName={t('button.copy')}
                onCopyClick={() => handleCopyClick('workup')}
                onEditClick={() => handleEditClick('workup')}
                iconCopy={<FaRegCopy />}
                iconEdit={<LuFileEdit />}
              />
            </>
          )}
        </div>
        <div className="mb-3">
          <h3 className="font-bold">
            Next steps in the management and follow-up
          </h3>
          {editing.nextSteps?.length ? (
            <>
              {editing.nextSteps.map(({ name, description }, index) => (
                <>
                  <p className="mb-1 text-label-primary">Name</p>
                  <ResizableTextareaField
                    value={name}
                    onChange={(text) => {
                      handleChangeName(text, index, 'nextSteps');
                    }}
                  />
                  <p className="mb-1 text-label-primary">Description</p>
                  <ResizableTextareaField
                    value={name}
                    onChange={(text) => {
                      handleChangeDescription(text, index, 'nextSteps');
                    }}
                  />
                  <Buttons
                    onSaveClick={() => handleSaveEditClick('nextSteps')}
                    onCancelClick={() => handleCancelEditClick()}
                    type="button"
                    save="Save"
                    cancel="Cancel"
                    iconCancel={<MdOutlineCancel />}
                    iconSave={<LuSave />}
                  />
                </>
              ))}
            </>
          ) : (
            <>
              {nextStepsContent?.map(({ name, description }, index) => (
                <div key={`next-steps-${index}`}>
                  <p className="mb-1 text-label-primary">{`${
                    index + 1
                  }. ${name}`}</p>
                  <p className="mb-1 text-label-secondary">{description}</p>
                </div>
              ))}
              <Buttons
                edit="Edit"
                editName={t('button.edit')}
                type="button"
                copy="Copy"
                copyName={t('button.copy')}
                onCopyClick={() => handleCopyClick('nextSteps')}
                onEditClick={() => handleEditClick('nextSteps')}
                iconCopy={<FaRegCopy />}
                iconEdit={<LuFileEdit />}
              />
            </>
          )}
        </div>
        <div className="mb-3">
          <h3 className="font-bold">References</h3>
          {referencesContent?.map((reference, index) => (
            <p
              key={`references-${index}`}
              className="mb-1 text-label-secondary"
            >
              {reference}
            </p>
          ))}
        </div>
        <div className="mb-3">
          <h5 className="font-bold">Disclaimer</h5>
          <p className="text-label-secondary text-sm">
            Our clinical decision support systems (CDSS) use OpenAI data to
            assist healthcare professionals, not to replace medical advice.
            Always consult a healthcare provider. Verify information
            independently. Users are responsible for decisions made based on
            CDSS. By using our CDSS, you agree to this disclaimer.
          </p>
        </div>
        <Button
          className="mt-2 w-full"
          icon={<RiVoiceprintLine />}
          iconSize="text-xl"
          onClick={() => handleNoteSave()}
        >
          Save CDSS
        </Button>
        <Button
          variant={'outline'}
          className=" mt-3 w-full"
          icon={<LuPenLine />}
          iconSize="text-xl"
          onClick={copyAllFields}
        >
          {t('note.copyAll')}
        </Button>
      </form>
    </div>
  );
};

export default CDSS;
