import React, { useState } from 'react';
import { Label } from '../ui/Label';
import { Select } from '../ui/Select/Select';
import { SelectTrigger } from '../ui/Select/SelectTrigger';
import { SelectValue } from '../ui/Select/SelectValue';
import { SelectContent } from '../ui/Select/SelectContent';
import { SelectGroup } from '../ui/Select/SelectGroup';
import { SelectItem } from '../ui/Select/SelectItem';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '../ui/Button';
import { CiCirclePlus } from 'react-icons/ci';
import { useTranslation } from 'react-i18next';
import {
  AlertDialog,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../ui/AlertDialog/AlertDialogParts';
import { AlertDialogContent } from '../ui/AlertDialog/AlertDialogContent';
import { AlertDialogCancel } from '../ui/AlertDialog/AlertDialogCancel';
import { AlertDialogAction } from '../ui/AlertDialog/AlertDialogAction';
import { InputWithLabel } from '../ReusableElements/InputWithLabel';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { format } from 'date-fns';
import PatientsApi from '../../api/PatientsApi';
import RoutingPath from '../../routing/RoutingPath';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast } from '../ui/Toast/UseToast';
import Loading from '../Loading/Loading';

export interface PatientForm {
  _id?: string;
  name?: string;
}

export interface SelectFieldOption {
  name: string;
  value: string;
}

interface NotePatientProps {
  inputLabel: string;
  options: SelectFieldOption[];
  onPatientChange: (value: PatientForm) => void;
  onAddedPatient: () => void;
}

type AddPatientFields = {
  name: string;
};

const schema = z.object({
  name: z.string().min(1, {
    message: 'You need to enter patient name.',
  }),
});

const NotePatient = ({
  options,
  inputLabel,
  onPatientChange,
  onAddedPatient,
}: NotePatientProps) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const { toast } = useToast();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    reset,
    register: patientNameFormRegister,
    formState: { errors: patientNameFormErrors },
  } = useForm<AddPatientFields>({
    resolver: zodResolver(schema),
  });

  const handleSelectChange = (value: string) => {
    const patient = options?.find((option) => option.value === value);
    onPatientChange({ _id: patient?.value, name: patient?.name });
  };

  const onSubmit: SubmitHandler<AddPatientFields> = async (data) => {
    try {
      setIsLoading(true);
      const currentDate = new Date();
      const formattedDate = format(currentDate, 'yyyy-MM-dd');
      const formattedTime = format(currentDate, 'HH:mm');

      await schema.parseAsync(data);
      const patientData = {
        ...data,
        lastUpdated: currentDate.toISOString(),
      };
      if (!user?.sub) {
        throw new Error('No user id');
      }
      const token = await getAccessTokenSilently();
      if (!token) {
        throw new Error('No access token');
      }
      await new PatientsApi().addPatient(
        user.sub,
        {
          name: patientData.name,
          lastUpdated: patientData.lastUpdated,
        },
        token
      );
      toast({
        title: 'Success',
        description: 'Patient added',
      });
      setIsOpen(false);
      reset();
      onAddedPatient();
      // navigate(RoutingPath.CREATE);
    } catch (errors) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Please try again in few moments or contact support',
      });
      console.error(errors);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Label className="font-bold">{inputLabel}</Label>
      <div className="flex w-full items-center">
        <Select onValueChange={handleSelectChange}>
          <SelectTrigger onClick={(e) => e.preventDefault()} className="w-full">
            <SelectValue
              onClick={(e) => e.preventDefault()}
              placeholder="Select"
            />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {options.map((patient, index) => (
                <SelectItem key={index} value={patient.value}>
                  {patient.name}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>

        <Button
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(true);
          }}
          className="ml-5"
          icon={<CiCirclePlus />}
          iconSize="text-xl"
        >
          {t('patient.addPatient')}
        </Button>
      </div>
      <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
        <AlertDialogContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {isLoading ? (
              <Loading customHeightClass="h-full" />
            ) : (
              <>
                <AlertDialogHeader>
                  <AlertDialogTitle>{t('patient.addPatient')}</AlertDialogTitle>
                  <AlertDialogDescription>
                    {t('patient.addPatientMsg')}
                  </AlertDialogDescription>
                  <div className="pt-5">
                    <InputWithLabel
                      register={patientNameFormRegister}
                      errors={patientNameFormErrors}
                      registerName="name"
                      placeholder={t('patient.name')}
                      label={t('patient.name')}
                    />
                  </div>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel onClick={() => reset()}>
                    Cancel
                  </AlertDialogCancel>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit(onSubmit)(e);
                    }}
                    className="ml-2"
                  >
                    Add new patient
                  </Button>
                </AlertDialogFooter>
              </>
            )}
          </form>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default NotePatient;
