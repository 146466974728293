import * as React from 'react';
import PropTypes from 'prop-types';

import { cn } from '../../../util/css';

export const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn('p-4 align-middle [&:has([role=checkbox])]:pr-0', className)}
    {...props}
  />
));
TableCell.displayName = 'TableCell';

TableCell.propTypes = {
  className: PropTypes.string,
};
