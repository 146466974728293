import React, { useEffect, useState } from 'react';
import { Button } from '../ui/Button';
import { useTranslation } from 'react-i18next';
import { CardTitle } from '../ui/Card/CardTitle';
import { CardDescription } from '../ui/Card/CardDescription';
import { Separator } from '../ui/Separator';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { RiVoiceprintLine } from 'react-icons/ri';
import { PreprocessingDialogMessage, SpeakerKey } from './RenderNote';
import ResizableTextareaField from '../Form/ResizableTextareaField';
import { useMutation } from '@tanstack/react-query';
import DialogApi from '../../api/DialogApi';
import { Buttons } from '../ReusableElements/Buttons';
import { LuFileEdit, LuSave } from 'react-icons/lu';
import { convertSecondsToMinutesAndSeconds } from '../../util/time';
import { Label } from '../ui/Label';
import { FaRegCopy } from 'react-icons/fa';
import { MdOutlineCancel } from 'react-icons/md';
import { toast } from '../ui/Toast/UseToast';
import { useUserData } from '../../context/UserContextProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { format } from 'date-fns';

type TranscriptionField = {
  doctor: string;
  patient: string;
};
type SelectValue = {
  language: string;
};

const schema = z.object({
  noteName: z.string().min(1, {
    message: 'This field can not be empty',
  }),
  patient: z.string().min(1, {
    message: 'This field can not be empty',
  }),
});

interface TranscriptionProps {
  id?: string;
  dialog?: PreprocessingDialogMessage[];
  speaker0?: SpeakerKey;
  speaker1?: SpeakerKey;
  onSectionSave?: (dialog: PreprocessingDialogMessage[]) => void;
  updatedAt?: string;
  onClick?: (dialog: PreprocessingDialogMessage[]) => void;
}

interface Speaker {
  type?: 'doctor' | 'patient';
  transcription?: string;
}

interface SpeakerConversation {
  [key: string]: Speaker | undefined;
  0?: Speaker;
  1?: Speaker;
}

interface TranscriptionForm {
  dialog?: PreprocessingDialogMessage[];
}

const Transcription = ({
  id,
  dialog,
  onClick,
  updatedAt,
  onSectionSave,
}: TranscriptionProps) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [form, setForm] = useState<TranscriptionForm | undefined>({
    dialog: dialog,
  });
  const { t } = useTranslation();
  const { register } = useForm();
  const [copiedIndex, setCopiedIndex] = useState<number | null>(null);

  const [dialogToSend, setDialogToSend] = useState<
    PreprocessingDialogMessage[] | undefined
  >(dialog);
  const [editedIndex, setEditedIndex] = useState<number | null>(null);
  const [editedTextMap, setEditedTextMap] = useState<{
    [index: number]: string;
  }>({});

  const [originalTextMap, setOriginalTextMap] = useState<{
    [index: number]: string;
  }>({});

  const mutation = useMutation({
    mutationFn: async (dialog: PreprocessingDialogMessage[]) => {
      if (!id) throw new Error('no valid id');
      if (!user?.sub) {
        throw new Error('No user id');
      }
      const token = await getAccessTokenSilently();
      if (!token) {
        throw new Error('No access token');
      }
      return new DialogApi().edit(id, user.sub, dialog, token);
    },
  });

  useEffect(() => {
    setForm({ dialog });
  }, [dialog]);

  const hasResults = Array.isArray(dialog);

  const handleEditClick = (index: number) => {
    setEditedIndex(index);
    setOriginalTextMap(editedTextMap);
  };

  const copyText = (text: string) => {
    const inputElement = document.createElement('textarea');
    inputElement.value = text;
    document.body.appendChild(inputElement);
    inputElement.select();
    document.execCommand('copy');
    document.body.removeChild(inputElement);
  };

  const handleCopyClick = (index: number, originalTextMap: string) => {
    if (editedTextMap[index]) {
      copyText(editedTextMap[index]);
      toast({
        title: 'Text copied!',
      });
    } else {
      copyText(originalTextMap);
      toast({
        title: 'Text copied!',
      });
    }
  };

  const handleSaveClick = (idx: number) => {
    setEditedIndex(null);
    setOriginalTextMap(editedTextMap);
    if (dialogToSend) {
      const hasDialogToSend = dialogToSend[idx];
      const newText = editedTextMap[idx];
      if (hasDialogToSend && newText) {
        const newDialogToSend = dialogToSend.map((d, i) => {
          return {
            ...d,
            text: i === idx ? newText : d.text,
          };
        });
        setDialogToSend(newDialogToSend);
        handleSave();
      }
    }
  };

  const handleCancelClick = () => {
    setEditedIndex(null);
    setEditedTextMap(originalTextMap);
  };

  const handleSave = () => {
    if (dialogToSend && onClick) {
      onClick(dialogToSend);
    }
  };

  return (
    <>
      <CardTitle>{t('sidebar.transcription')}</CardTitle>
      <CardDescription className="mt-1">
        {t('note.transcriptionInfo')}
      </CardDescription>
      {updatedAt ? (
        <CardDescription className="mt-1">
          Last updated: {format(new Date(updatedAt), 'dd-MM-yyyy HH:mm:ss')}
        </CardDescription>
      ) : null}
      <Separator className="my-5" />
      {!hasResults ? <p>No results</p> : null}
      {hasResults
        ? dialog.map(({ text, speaker, start, end }, index) => {
            return (
              <div key={`result-textarea-${index}`}>
                {editedIndex === index ? (
                  <>
                    <div className="mb-1 flex justify-between">
                      <ResizableTextareaField
                        key={`result-textarea-${index}`}
                        value={editedTextMap[index] || text}
                        onChange={(editedText) => {
                          setEditedTextMap((prev) => ({
                            ...prev,
                            [index]: editedText,
                          }));
                        }}
                        label={convertSecondsToMinutesAndSeconds(start)}
                      />
                    </div>
                    <div>
                      <Buttons
                        onSaveClick={() => handleSaveClick(index)}
                        onCancelClick={() => handleCancelClick()}
                        type="button"
                        save="Save"
                        saveName={t('button.save')}
                        cancel="Cancel"
                        cancelName={t('button.cancel')}
                        iconCancel={<MdOutlineCancel />}
                        iconSave={<LuSave />}
                      />
                    </div>
                  </>
                ) : (
                  <div className="mb-3 flex justify-between">
                    <div>
                      <Label className="font-bold">
                        {convertSecondsToMinutesAndSeconds(start)}
                      </Label>
                      <p className="text-label-secondary">
                        {originalTextMap[index] || text}
                      </p>
                    </div>
                    <div className="mt-5">
                      <Buttons
                        edit="Edit"
                        type="button"
                        copy="Copy"
                        onEditClick={() => handleEditClick(index)}
                        onCopyClick={() => handleCopyClick(index, text)}
                        iconCopy={<FaRegCopy />}
                        iconEdit={<LuFileEdit />}
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })
        : null}
      <Button
        onClick={() => handleSave()}
        className=" mt-5 w-full"
        icon={<RiVoiceprintLine />}
        iconSize="text-xl"
      >
        {t('note.transcriptionSave')}
      </Button>
    </>
  );
};
export default Transcription;
