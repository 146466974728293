import axios from 'axios';
import { HOST } from './config';

type Message = {
  title: string;
  description: string;
  sentTime: string;
};

export default class MessageApi {
  async send(userId: string, message: Message, accessToken: string) {
    try {
      return axios.post(
        `${HOST}/audioapi/message?userId=${encodeURIComponent(userId)}`,
        message,
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } catch (error) {
      console.error('Error while sending message:', error);
      throw error;
    }
  }

  async sendContactSales(
    userId: string,
    message: Message,
    accessToken: string
  ) {
    try {
      return axios.post(
        `${HOST}/audioapi/sales?userId=${encodeURIComponent(userId)}`,
        message,
        {
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
    } catch (error) {
      console.error('Error while sending message:', error);
      throw error;
    }
  }
}
