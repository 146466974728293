import React from 'react';
import { cn } from '../util/css';
import {
  Banknote,
  Mail,
  PlaySquare,
  Smile,
  User,
  BookmarkCheck,
  LanguagesIcon,
  Scale,
  Power,
} from 'lucide-react';
import RoutingPath from '../routing/RoutingPath';
import TabProps from '../routing/TabProps';
import { SidebarButton } from './ui/SidebarButton';
import { SidebarHeader } from './SidebarHeader';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from './ui/Button';
import { useAuth0 } from '@auth0/auth0-react';

interface SidebarProps extends TabProps, React.HTMLAttributes<HTMLDivElement> {
  selected?: string;
}

export function Sidebar({ selected, className }: SidebarProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const handleRedirectToPrivacy = () => {
    window.location.href = 'https://zirr.ai/privacy';
  };

  return (
    <div className={cn('pb-12', className)}>
      <div className="space-y-4 py-4">
        <div className="px-3 py-2">
          <SidebarHeader label={t('sidebar.soapNotes')} />
          <div className="space-y-1">
            <SidebarButton
              label={t('sidebar.create')}
              isSelected={selected === RoutingPath.CREATE || selected === '/'}
              Icon={PlaySquare}
              onClick={() => navigate(RoutingPath.CREATE)}
            />
            <SidebarButton
              label={t('sidebar.saved')}
              isSelected={selected === RoutingPath.SAVED}
              Icon={BookmarkCheck}
              onClick={() => navigate(RoutingPath.SAVED)}
            />
          </div>
        </div>
        <div className="px-3 py-2">
          <SidebarHeader label={t('sidebar.patients')} />
          <div className="space-y-1">
            <SidebarButton
              label={t('sidebar.all')}
              isSelected={selected === RoutingPath.PATIENTS}
              Icon={Smile}
              onClick={() => navigate(RoutingPath.PATIENTS)}
            />
          </div>
        </div>
        <div className="p-2">
          <SidebarHeader label={t('sidebar.settings')} />
          <div className="space-y-1">
            <SidebarButton
              label={t('sidebar.transcription')}
              isSelected={selected === RoutingPath.TRANSCRIPTION}
              Icon={LanguagesIcon}
              onClick={() => navigate(RoutingPath.TRANSCRIPTION)}
            />
            <SidebarButton
              label={t('sidebar.account')}
              isSelected={selected === RoutingPath.ACCOUNT}
              Icon={User}
              onClick={() => navigate(RoutingPath.ACCOUNT)}
            />
            <SidebarButton
              label={t('sidebar.payment')}
              isSelected={selected === RoutingPath.PAYMENT}
              Icon={Banknote}
              onClick={() => navigate(RoutingPath.PAYMENT)}
            />
            <SidebarButton
              label={t('sidebar.support')}
              isSelected={selected === RoutingPath.SUPPORT}
              Icon={Mail}
              onClick={() => navigate(RoutingPath.SUPPORT)}
            />
            <SidebarButton
              label={t('sidebar.privacy')}
              isSelected={selected === RoutingPath.PRIVACY}
              Icon={Scale}
              onClick={handleRedirectToPrivacy}
            />
            <Button
              type="button"
              variant="outline"
              className="flex-1 w-full"
              icon={<Power />}
              iconSize="text-xl"
              onClick={handleLogout}
            >
              Log out
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
