import React from 'react';
import './ResizableTextarea.css';
import { Label } from '../ui/Label';
import ResizableTextarea from './ResizableTextarea';

interface ResizableTextareaProps {
  label?: string;
  value?: string;
  placeholder?: string;
  onChange: (value: string) => void;
}

const ResizableTextareaField = ({
  label,
  value,
  onChange,
  placeholder,
}: ResizableTextareaProps) => {
  return (
    <div className="mb-3 grid w-full gap-1.5">
      <Label className="font-bold">{label}</Label>
      <ResizableTextarea
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default ResizableTextareaField;
