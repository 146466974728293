export type CreateNoteRoutingPathKey =
  | 'create'
  | 'patient'
  | 'recording'
  | 'transcriptionprocess'
  | 'result'
  | 'list'
  | 'error'
  | 'transcription'
  | 'addpatient';

export default class CreateNoteRoutingPath {
  static CREATE = '/create';
  static PATIENT = '/patient';
  static RECORDING = '/recording';
  static TRANSCRIPTIONPROCESS = '/transcriptionprocess';
  static RESULT = '/result';
  static LIST = '/list';
  static ERROR = '/error';
  static TRANSCRIPTION = '/transcription';
  static ADDPATIENT = '/addpatient';

  private static MAP = {
    create: CreateNoteRoutingPath.CREATE,
    patient: CreateNoteRoutingPath.PATIENT,
    recording: CreateNoteRoutingPath.RECORDING,
    transcriptionprocess: CreateNoteRoutingPath.TRANSCRIPTIONPROCESS,
    result: CreateNoteRoutingPath.RESULT,
    list: CreateNoteRoutingPath.LIST,
    error: CreateNoteRoutingPath.ERROR,
    transcription: CreateNoteRoutingPath.TRANSCRIPTION,
    addpatient: CreateNoteRoutingPath.ADDPATIENT,
  };

  static get(name: CreateNoteRoutingPathKey): CreateNoteRoutingPath {
    return this.MAP[name];
  }

  static getAll() {
    return [
      this.PATIENT,
      this.RECORDING,
      this.TRANSCRIPTIONPROCESS,
      this.RESULT,
      this.LIST,
      this.ADDPATIENT,
    ];
  }
}
