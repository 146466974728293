import React, { useEffect, useRef, useState } from 'react';
import Page from '../Page/Page';
import { CardTitle } from '../ui/Card/CardTitle';
import { CardDescription } from '../ui/Card/CardDescription';
import { Button } from '../ui/Button';
import { MdOutlineKeyboardVoice } from 'react-icons/md';
import { RiVoiceprintLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { TbPointFilled } from 'react-icons/tb';
import { CgTranscript } from 'react-icons/cg';

interface RecordingProcessProps {
  isRecording: boolean;
  onStop: () => void;
}

const RecordingProcess = ({ isRecording, onStop }: RecordingProcessProps) => {
  const { t } = useTranslation();
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (isRecording) {
      const intervalId = setInterval(() => {
        setCounter((prevCounter) => prevCounter + 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [isRecording]);

  const formatTime = (timeInSeconds: number) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="flex h-[500px] flex-col items-center justify-center text-center">
      {isRecording ? (
        <>
          <CardTitle className="mb-2">{t('note.recordInfo')}</CardTitle>
          <CardDescription className="mb-12">
            {t('note.recordStopInfo')}
          </CardDescription>
          <p className="mb-3 flex items-center text-3xl font-bold">
            <TbPointFilled className="inline text-lg text-red-600" />
            {formatTime(counter)}
          </p>
          <div className="relative mt-10 flex h-[70px] items-center justify-center ">
            <div
              className={`absolute flex size-[70px] items-center justify-center rounded-full bg-primary wave-effect`}
            ></div>
            <div
              className="absolute flex size-[70px] items-center justify-center rounded-full bg-primary "
              onClick={onStop}
            >
              <MdOutlineKeyboardVoice className="text-4xl text-white" />
            </div>
          </div>
          <Button
            className="mt-10 w-full"
            icon={<RiVoiceprintLine />}
            iconSize="text-xl"
            onClick={onStop}
          >
            {t('note.recordStop')}
          </Button>
        </>
      ) : (
        <CardTitle className="mb-2">
          There was an error with audio recording
        </CardTitle>
      )}
    </div>
  );
};

export default RecordingProcess;
