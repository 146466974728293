import React, { useEffect, useState } from 'react';
import { Button } from '../ui/Button';
import { useTranslation } from 'react-i18next';
import { CardTitle } from '../ui/Card/CardTitle';
import { CardDescription } from '../ui/Card/CardDescription';
import { Separator } from '../ui/Separator';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { RiVoiceprintLine } from 'react-icons/ri';
import { LuFileEdit, LuPenLine } from 'react-icons/lu';
import { LuSave } from 'react-icons/lu';
import { SOAPNote } from './RenderNote';
import { Buttons } from '../ReusableElements/Buttons';
import { MdOutlineCancel } from 'react-icons/md';
import { FaRegCopy } from 'react-icons/fa';
import ResizableTextareaField from '../Form/ResizableTextareaField';
import { toast } from '../ui/Toast/UseToast';
import { format } from 'date-fns';

type FormValues = {
  subjective: string;
  objective: string;
  assessment: string;
  plan: string;
  summary: string;
};

const schema = z.object({
  subjective: z.string().min(1, {
    message: 'This field can not be empty',
  }),
  objective: z.string().min(1, {
    message: 'This field can not be empty',
  }),
  assessment: z.string().min(1, {
    message: 'This field can not be empty',
  }),
  plan: z.string().min(1, {
    message: 'This field can not be empty',
  }),
  summary: z.string().min(1, {
    message: 'This field can not be empty',
  }),
});
interface SOAPNotesListProps {
  id?: string;
  patientName?: string;
  note?: SOAPNote;
  updatedAt?: string;
  onClick: (note: SOAPNote) => void;
}

const SOAPNotesList = ({
  note,
  onClick,
  updatedAt,
  patientName,
}: SOAPNotesListProps) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState({
    subjective: false,
    objective: false,
    assessment: false,
    plan: false,
    summary: false,
  });

  const [originalSubjectiveContent, setOriginalSubjectiveContent] =
    useState('');
  const [subjectiveContent, setSubjectiveContent] = useState('');

  const [originalObjectiveContent, setOriginalObjectiveContent] = useState('');
  const [objectiveContent, setObjectiveContent] = useState('');

  const [originalAssessmentContent, setOriginalAssessmentContent] =
    useState('');
  const [assessmentContent, setAssessmentContent] = useState('');

  const [originalPlanContent, setOriginalPlanContent] = useState('');
  const [planContent, setPlanContent] = useState('');

  const [originalSummaryContent, setOriginalSummaryContent] = useState('');
  const [summaryContent, setSummaryContent] = useState('');

  useEffect(() => {
    setSubjectiveContent(note?.subjective || '');
    setObjectiveContent(note?.objective || '');
    setAssessmentContent(note?.assessment || '');
    setPlanContent(note?.plan || '');
    setSummaryContent(note?.summary || '');
  }, [note]);

  const handleEditClick = (type: string) => {
    if (type === 'subjective') {
      clearErrors('subjective');
      setOriginalSubjectiveContent(subjectiveContent);
      setIsEditing({ ...isEditing, subjective: true });
    } else if (type === 'objective') {
      clearErrors('objective');
      setOriginalObjectiveContent(objectiveContent);
      setIsEditing({ ...isEditing, objective: true });
    } else if (type === 'assessment') {
      clearErrors('assessment');
      setOriginalAssessmentContent(assessmentContent);
      setIsEditing({ ...isEditing, assessment: true });
    } else if (type === 'plan') {
      clearErrors('plan');
      setOriginalPlanContent(planContent);
      setIsEditing({ ...isEditing, plan: true });
    } else if (type === 'summary') {
      clearErrors('summary');
      setOriginalSummaryContent(summaryContent);
      setIsEditing({ ...isEditing, summary: true });
    }
  };
  const handleSaveClick = (type: string) => {
    if (type === 'subjective') {
      setSubjectiveContent(subjectiveContent);
      setIsEditing({ ...isEditing, subjective: false });
    } else if (type === 'objective') {
      setObjectiveContent(objectiveContent);
      setIsEditing({ ...isEditing, objective: false });
    } else if (type === 'assessment') {
      setAssessmentContent(assessmentContent);
      setIsEditing({ ...isEditing, assessment: false });
    } else if (type === 'plan') {
      setPlanContent(planContent);
      setIsEditing({ ...isEditing, plan: false });
    } else if (type === 'summary') {
      setSummaryContent(summaryContent);
      setIsEditing({ ...isEditing, summary: false });
    }
    handleNoteSave();
  };
  const handleCancelClick = (type: string) => {
    if (type === 'subjective') {
      setSubjectiveContent(originalSubjectiveContent);
      setIsEditing({ ...isEditing, subjective: false });
    } else if (type === 'objective') {
      setObjectiveContent(originalObjectiveContent);
      setIsEditing({ ...isEditing, objective: false });
    } else if (type === 'assessment') {
      setAssessmentContent(originalAssessmentContent);
      setIsEditing({ ...isEditing, assessment: false });
    } else if (type === 'plan') {
      setPlanContent(originalPlanContent);
      setIsEditing({ ...isEditing, plan: false });
    } else if (type === 'summary') {
      setSummaryContent(originalSummaryContent);
      setIsEditing({ ...isEditing, summary: false });
    }
  };

  const copyText = (text: string) => {
    const inputElement = document.createElement('textarea');
    inputElement.value = text;
    document.body.appendChild(inputElement);
    inputElement.select();
    document.execCommand('copy');
    document.body.removeChild(inputElement);
  };

  const handleCopyClick = (type: string, text: string) => {
    copyText(text);

    toast({
      title: 'Text copied!',
    });
  };

  const copyAllFields = () => {
    const allContent = `Subjective: ${subjectiveContent}\nObjective: ${objectiveContent}\nAssessment: ${assessmentContent}\nPlan: ${planContent}\nSummary: ${summaryContent}`;
    copyText(allContent);

    toast({
      title: 'All fields copied!',
    });
  };

  const {
    handleSubmit,
    register,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    console.log(data);
  };

  const handleNoteSave = () => {
    onClick({
      subjective: subjectiveContent,
      objective: objectiveContent,
      assessment: assessmentContent,
      plan: planContent,
      summary: summaryContent,
    });
  };

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSubmit)(e);
        }}
      >
        <CardTitle>
          {patientName ? `${patientName} - ` : ''}
          {t('note.soapNote')}
        </CardTitle>
        <CardDescription className="mt-1">
          {t('note.soapNoteInfo')}
        </CardDescription>
        {updatedAt ? (
          <CardDescription className="mt-1">
            Last updated: {format(new Date(updatedAt), 'dd-MM-yyyy HH:mm:ss')}
          </CardDescription>
        ) : null}
        <Separator className="my-5" />
        <div className="mb-3">
          <h3 className="font-bold">{t('note.subjective')}</h3>
          {!isEditing.subjective ? (
            <>
              <p className="mb-1 text-label-secondary">{subjectiveContent}</p>
              <Buttons
                edit="Edit"
                editName={t('button.edit')}
                type="button"
                copy="Copy"
                copyName={t('button.copy')}
                onCopyClick={() =>
                  handleCopyClick('subjective', subjectiveContent)
                }
                onEditClick={() => handleEditClick('subjective')}
                iconCopy={<FaRegCopy />}
                iconEdit={<LuFileEdit />}
              />
            </>
          ) : (
            <Controller
              name="subjective"
              control={control}
              defaultValue={subjectiveContent}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <>
                  <ResizableTextareaField
                    {...field}
                    value={subjectiveContent}
                    onChange={(text) => {
                      field.onChange(text);
                      setSubjectiveContent(text);
                    }}
                  />
                  {errors.subjective && (
                    <p className="mb-2 text-xs text-red-500">
                      {errors.subjective.message}
                    </p>
                  )}
                  <Buttons
                    onSaveClick={() => handleSaveClick('subjective')}
                    onCancelClick={() => handleCancelClick('subjective')}
                    type="button"
                    save="Save"
                    cancel="Cancel"
                    iconCancel={<MdOutlineCancel />}
                    iconSave={<LuSave />}
                  />
                </>
              )}
            />
          )}
        </div>
        <div className="mb-3">
          <h3 className="font-bold">{t('note.objective')}</h3>
          {!isEditing.objective ? (
            <>
              <p className="mb-1 text-label-secondary">{objectiveContent}</p>
              <Buttons
                edit="Edit"
                editName={t('button.edit')}
                type="button"
                copy="Copy"
                copyName={t('button.copy')}
                onCopyClick={() =>
                  handleCopyClick('objective', objectiveContent)
                }
                onEditClick={() => handleEditClick('objective')}
                iconCopy={<FaRegCopy />}
                iconEdit={<LuFileEdit />}
              />
            </>
          ) : (
            <Controller
              name="objective"
              control={control}
              defaultValue={objectiveContent}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <>
                  <ResizableTextareaField
                    {...field}
                    value={objectiveContent}
                    onChange={(text) => {
                      field.onChange(text);
                      setObjectiveContent(text);
                    }}
                  />
                  {errors.objective && (
                    <p className="mb-2 text-xs text-red-500">
                      {errors.objective.message}
                    </p>
                  )}
                  <Buttons
                    onSaveClick={() => handleSaveClick('objective')}
                    onCancelClick={() => handleCancelClick('objective')}
                    type="button"
                    save="Save"
                    cancel="Cancel"
                    iconCancel={<MdOutlineCancel />}
                    iconSave={<LuSave />}
                  />
                </>
              )}
            />
          )}
        </div>
        <div className="mb-3">
          <h3 className="font-bold">{t('note.assessment')}</h3>
          {!isEditing.assessment ? (
            <>
              <p className="mb-1 text-label-secondary">{assessmentContent}</p>
              <Buttons
                edit="Edit"
                editName={t('button.edit')}
                type="button"
                copy="Copy"
                copyName={t('button.copy')}
                onCopyClick={() =>
                  handleCopyClick('assessment', assessmentContent)
                }
                onEditClick={() => handleEditClick('assessment')}
                iconCopy={<FaRegCopy />}
                iconEdit={<LuFileEdit />}
              />
            </>
          ) : (
            <Controller
              name="assessment"
              control={control}
              defaultValue={assessmentContent}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <>
                  <ResizableTextareaField
                    {...field}
                    value={assessmentContent}
                    onChange={(text) => {
                      field.onChange(text);
                      setAssessmentContent(text);
                    }}
                  />
                  {errors.assessment && (
                    <p className="mb-2 text-xs text-red-500">
                      {errors.assessment.message}
                    </p>
                  )}
                  <Buttons
                    onSaveClick={() => handleSaveClick('assessment')}
                    onCancelClick={() => handleCancelClick('assessment')}
                    type="button"
                    save="Save"
                    cancel="Cancel"
                    iconCancel={<MdOutlineCancel />}
                    iconSave={<LuSave />}
                  />
                </>
              )}
            />
          )}
        </div>
        <div className="mb-3">
          <h3 className="font-bold">{t('note.plan')}</h3>
          {!isEditing.plan ? (
            <>
              <p className="mb-1 text-label-secondary">{planContent}</p>
              <Buttons
                edit="Edit"
                editName={t('button.edit')}
                type="button"
                copy="Copy"
                copyName={t('button.copy')}
                onCopyClick={() => handleCopyClick('plan', planContent)}
                onEditClick={() => handleEditClick('plan')}
                iconCopy={<FaRegCopy />}
                iconEdit={<LuFileEdit />}
              />
            </>
          ) : (
            <Controller
              name="plan"
              control={control}
              defaultValue={planContent}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <>
                  <ResizableTextareaField
                    {...field}
                    value={planContent}
                    onChange={(text) => {
                      field.onChange(text);
                      setPlanContent(text);
                    }}
                  />
                  {errors.plan && (
                    <p className="mb-2 text-xs text-red-500">
                      {errors.plan.message}
                    </p>
                  )}
                  <Buttons
                    onSaveClick={() => handleSaveClick('plan')}
                    onCancelClick={() => handleCancelClick('plan')}
                    type="button"
                    save="Save"
                    cancel="Cancel"
                    iconCancel={<MdOutlineCancel />}
                    iconSave={<LuSave />}
                  />
                </>
              )}
            />
          )}
        </div>
        <div className="mb-3">
          <h3 className="font-bold">{t('note.summary')}</h3>
          {!isEditing.summary ? (
            <>
              <p className="mb-1 text-label-secondary">{summaryContent}</p>
              <Buttons
                edit="Edit"
                editName={t('button.edit')}
                type="button"
                copy="Copy"
                copyName={t('button.copy')}
                onCopyClick={() => handleCopyClick('summary', summaryContent)}
                onEditClick={() => handleEditClick('summary')}
                iconCopy={<FaRegCopy />}
                iconEdit={<LuFileEdit />}
              />
            </>
          ) : (
            <Controller
              name="summary"
              control={control}
              defaultValue={summaryContent}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <>
                  <ResizableTextareaField
                    {...field}
                    value={summaryContent}
                    onChange={(text) => {
                      field.onChange(text);
                      setSummaryContent(text);
                    }}
                  />
                  {errors.summary && (
                    <p className="mb-2 text-xs text-red-500">
                      {errors.summary.message}
                    </p>
                  )}
                  <Buttons
                    onSaveClick={() => handleSaveClick('summary')}
                    onCancelClick={() => handleCancelClick('summary')}
                    type="button"
                    save="Save"
                    saveName={t('button.save')}
                    cancel="Cancel"
                    cancelName={t('button.cancel')}
                    iconCancel={<MdOutlineCancel />}
                    iconSave={<LuSave />}
                  />
                </>
              )}
            />
          )}
        </div>
        <Button
          className=" mt-2 w-full"
          icon={<RiVoiceprintLine />}
          iconSize="text-xl"
          onClick={() => handleNoteSave()}
        >
          {t('note.saveSoap')}
        </Button>
        <Button
          variant={'outline'}
          className=" mt-3 w-full"
          icon={<LuPenLine />}
          iconSize="text-xl"
          onClick={copyAllFields}
        >
          {t('note.copyAll')}
        </Button>
      </form>
    </div>
  );
};

export default SOAPNotesList;
