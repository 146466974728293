import axios from 'axios';
import { HOST } from './config';
import Subscription from '../payment/Subscription';

export default class PaymentApi {
  async startSubscription(
    userId: string,
    form: Subscription,
    accessToken: string
  ) {
    return axios.post(
      `${HOST}/audioapi/subscription/${encodeURIComponent(
        userId
      )}?userId=${encodeURIComponent(userId)}`,
      form,
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
}
