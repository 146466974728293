import { SOAPNote } from '../component/Note/RenderNote';
import axios from 'axios';
import { HOST } from './config';
import EditResultNameRequest from '../result/EditResultNameRequest';

export interface EditSOAPNote {
  note?: SOAPNote;
}

export default class NotesApi {
  async edit(id: string, userId: string, note: SOAPNote, accessToken: string) {
    return axios.put<EditSOAPNote>(
      `${HOST}/audioapi/note/${id}?userId=${encodeURIComponent(userId)}`,
      {
        note,
      },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  async delete(id: string, userId: string, accessToken: string) {
    return axios.delete<EditSOAPNote>(
      `${HOST}/audioapi/note/${id}?userId=${encodeURIComponent(userId)}`,
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }

  async editName(
    id: string,
    userId: string,
    note: EditResultNameRequest,
    accessToken: string
  ) {
    return axios.put<EditSOAPNote>(
      `${HOST}/audioapi/details/${id}?userId=${encodeURIComponent(userId)}`,
      {
        note,
      },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
}
