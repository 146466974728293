import React, { createContext, ReactNode, useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import UserApi from '../api/UserApi';
import UserData from '../user/UserData';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../component/Loading/Loading';

interface UserContextProps {
  userId: string;
  setUserId: (val: string) => void;
  userData: UserData | null;
  setUserData: (val: UserData | null) => void;
}

interface UserContextProviderProps {
  children: ReactNode;
}

export const UserContext = createContext<UserContextProps>({
  userId: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUserId: (val) => {},
  userData: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setUserData: (val) => {},
});

export default function UserContextProvider({
  children,
}: UserContextProviderProps) {
  const {
    isAuthenticated,
    user,
    getAccessTokenSilently,
    error,
    getAccessTokenWithPopup,
    handleRedirectCallback,
  } = useAuth0();
  const [userId, setUserId] = useState('');
  const [userData, setUserData] = useState<UserData | null>(null);

  const { isLoading } = useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      try {
        const token = await getAccessTokenSilently();
        if (!isAuthenticated || !user?.email || !user?.sub) {
          throw new Error('No user id');
        }
        const foundUser = await new UserApi().get(user.sub, user.email, token);
        if (!foundUser._id) {
          throw new Error('Found user has no id');
        }
        console.log('SET USER DATA', foundUser);
        setUserData(foundUser);
        setUserId(foundUser._id);
      } catch (error) {
        console.error('Error AUTH check', error);
        setUserId('');
        setUserData(null);
      }
    },
    retry: false,
  });

  if (isLoading) {
    return <Loading viewportHeight />;
  }

  return (
    <UserContext.Provider
      value={{
        userId,
        setUserId,
        userData,
        setUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useUserData = () => useContext(UserContext);
