import './i18n';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Page from './component/Page/Page';
import CreateNoteContextProvider from './context/CreateNoteContextProvider';
import RenderNote from './component/Note/RenderNote';
import Loading from './component/Loading/Loading';
import RoutingPath from './routing/RoutingPath';
import AddPatient from './component/Patients/AddPatient';
import SoapNoteDetailsTab from './component/Note/SoapNoteDetailsTab';
import SoapNotesTable from './component/Note/NotesTable';
import PatientsTable from './component/Patients/PatientsTable';
import Support from './component/Support/Support';
import ContactSales from './component/Support/ContactSales';
import Payment from './component/Payment/Payment';
import PlanChange from './component/Payment/PlanChange';
import PatientProfile from './component/Patients/PatientProfile';
import Login from './component/Page/Login';
import PaymentAnswer from './component/PaymentAnswer/RecordingAnswer';
import UserContextProvider from './context/UserContextProvider';
import { Toaster } from './component/ui/Toast/Toaster';
import TranscriptionSettings from './component/TranscriptionSettings';
import EditAccountPage from './component/Account/EditAccountPage';
import AccountPage from './component/Account/AccountPage';
import VerifyEmailPage from './component/Page/VerifyEmailPage';

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

export const App: React.FC = () => {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <Loading viewportHeight />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <CreateNoteContextProvider>
          <Routes>
            <Route path="/" element={<RenderNote />} />
            <Route path={RoutingPath.CREATE} element={<RenderNote />} />
            <Route path={RoutingPath.ADDPATIENT} element={<AddPatient />} />
            <Route
              path={RoutingPath.EDIT_ACCOUNT}
              element={<EditAccountPage />}
            />
            <Route path={RoutingPath.ACCOUNT} element={<AccountPage />} />
            <Route
              path={RoutingPath.TRANSCRIPTION}
              element={
                <Page>
                  <TranscriptionSettings />
                </Page>
              }
            />
            <Route
              path={RoutingPath.SAVED}
              element={
                <Page>
                  <SoapNotesTable />
                </Page>
              }
            />
            <Route
              path="/soap-note/:resultId"
              element={
                <Page>
                  <SoapNoteDetailsTab />
                </Page>
              }
            />
            <Route
              path={RoutingPath.PATIENTS}
              element={
                <Page>
                  <PatientsTable />
                </Page>
              }
            />
            <Route
              path={RoutingPath.SUPPORT}
              element={
                <Page>
                  <Support />
                </Page>
              }
            />
            <Route
              path={RoutingPath.CONTACT_SALES}
              element={
                <Page>
                  <ContactSales />
                </Page>
              }
            />
            <Route path={RoutingPath.PRIVACY} element={<Page>Privacy</Page>} />
            <Route
              path={RoutingPath.PAYMENT}
              element={
                <Page>
                  <Payment />
                </Page>
              }
            />
            <Route
              path={RoutingPath.CHANGE_PLAN}
              element={
                <Page>
                  <PlanChange />
                </Page>
              }
            />
            <Route
              path={`/patient/:patientId`}
              element={
                <Page>
                  <PatientProfile />
                </Page>
              }
            />
            <Route path={RoutingPath.LOGIN} element={<Login />} />
            <Route
              path={RoutingPath.VERIFY_EMAIL}
              element={<VerifyEmailPage />}
            />
            <Route
              path={RoutingPath.PAYMENT_SUCCESS}
              element={
                <Page>
                  <PaymentAnswer variant="success" />
                </Page>
              }
            />
            <Route
              path={RoutingPath.PAYMENT_FAILED}
              element={
                <Page>
                  <PaymentAnswer variant="error" />
                </Page>
              }
            />
            <Route path="*" element={<RenderNote />} />
            {/*<Route path="/" element={HomePage} />*/}
            {/*<Route path="/profile" element={<Profile />} />*/}
            {/*<Route path="/public" element={PublicPage} />*/}
            {/*<Route path="/protected" element={<Protected />} />*/}
            {/*<Route path="/admin" element={<Admin />} />*/}
            {/*<Route path="/callback" element={CallbackPage} />*/}
            {/*<Route path="*" element={NotFoundPage} />*/}
          </Routes>
          <Toaster />
        </CreateNoteContextProvider>
      </UserContextProvider>
    </QueryClientProvider>
  );
};
