import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './app';
import { Auth0ProviderWithHistory } from './auth0-provider-with-history';
// import './styles/styles.css';
import './style/globals.css';
import './index.css';
import './bufferPolyfill';

const Main = () => {
  try {
    return (
      <React.StrictMode>
        <BrowserRouter>
          <Auth0ProviderWithHistory>
            <App />
          </Auth0ProviderWithHistory>
        </BrowserRouter>
      </React.StrictMode>
    );
  } catch (e) {
    console.log(e);
    return <></>;
  }
};

ReactDOM.render(<Main />, document.getElementById('root'));
