import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '../ui/Toast/UseToast';
import { Separator } from '../ui/Separator';
import { Button } from '../ui/Button';
import { useNavigate } from 'react-router-dom';
import RoutingPath from '../../routing/RoutingPath';
import { useUserData } from '../../context/UserContextProvider';

export default function Payment() {
  const { userData } = useUserData();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { t } = useTranslation();

  const hasFreePlan = !userData?.plan || userData?.plan === 'free';
  const sessionsLeft =
    (userData?.sessionsLeft || 0) > 12 ? 'unlimited' : userData?.sessionsLeft;
  const status = userData?.subscriptionStatus || '-';

  let plan = '-';
  switch (userData?.plan) {
    case 'free':
      plan = 'Free';
      break;
    case 'pro':
      plan = 'Solo Pro';
      break;
    case 'enterprise':
      plan = 'Enterprise';
      break;
  }
  let time = '-';
  switch (userData?.subscriptionTime) {
    case 'monthly':
      time = 'Monthly';
      break;
    case 'yearly':
      time = 'Yearly';
      break;
  }

  const handleRedirect = () => {
    window.location.href = `https://billing.stripe.com/p/login/00g4h7cRWbqvcgw3cc?prefilled_email=${
      userData?.email || ''
    }`;
  };

  return (
    <div className="size-full">
      <h2 className="text-2xl font-bold">{t('payment.title')}</h2>
      <p className="text-sm text-gray-500">{t('payment.description')}</p>
      <Separator className="my-5" />
      <div className=" grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4 mb-5">
        <div>
          <p className="text-gray-500">{t('payment.plan')}</p>
          <p className="text-lg font-bold">{plan}</p>
        </div>
        <div>
          <p className="text-gray-500">{t('payment.period')}</p>
          <p className="text-lg font-bold">{time}</p>
        </div>
        <div>
          <p className="text-gray-500">{t('payment.sessionsLeft')}</p>
          <p className="text-lg font-bold">{sessionsLeft}</p>
        </div>
        <div>
          <p className="text-gray-500">{t('payment.status')}</p>
          <p className="text-lg font-bold">{status}</p>
        </div>
      </div>
      <Button
        className="w-full mb-3"
        onClick={() => navigate(RoutingPath.CHANGE_PLAN)}
      >
        {t('button.changePlan')}
      </Button>
      {plan ? (
        <Button
          onClick={handleRedirect}
          asChild
          className="w-full"
          variant="outline"
        >
          {t('button.manageSubscription')}
        </Button>
      ) : null}
    </div>
  );
}
