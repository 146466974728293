import React, { createContext, ReactNode, useContext, useState } from 'react';

interface CreateNoteContextProps {
  name: string;
  setName: (id: string) => void;
  patientId: string;
  setPatientId: (id: string) => void;
}

interface CreateNoteContextProviderProps {
  children: ReactNode;
}

export const CreateNoteContext = createContext<CreateNoteContextProps>({
  name: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setName: (val) => {},
  patientId: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPatientId: (val) => {},
});

export default function CreateNoteContextProvider({
  children,
}: CreateNoteContextProviderProps) {
  const [name, setName] = useState('');
  const [patientId, setPatientId] = useState('');

  return (
    <CreateNoteContext.Provider
      value={{
        name,
        setName,
        patientId,
        setPatientId,
      }}
    >
      {children}
    </CreateNoteContext.Provider>
  );
}

export function useCreateNoteContext() {
  return useContext(CreateNoteContext);
}
