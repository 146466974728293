import React from 'react';
import { CardTitle } from '../ui/Card/CardTitle';
import { CardDescription } from '../ui/Card/CardDescription';
import { TiTickOutline } from 'react-icons/ti';
import { useTranslation } from 'react-i18next';
import { RxCross2 } from 'react-icons/rx';
import { Button } from '../ui/Button';
import { useNavigate } from 'react-router-dom';

type PaymentAnswerVariant = 'success' | 'error';

interface PaymentAnswerProps {
  variant: PaymentAnswerVariant;
}

const PaymentAnswer = ({ variant }: PaymentAnswerProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex h-[500px] flex-col items-center justify-center text-center">
      {variant === 'success' ? (
        <>
          <div className="relative mb-10 flex h-[70px] items-center justify-center">
            <div className="absolute flex size-[70px] items-center justify-center rounded-full bg-primary ">
              <TiTickOutline className="text-5xl text-white" />
            </div>
          </div>
          <CardTitle className="mb-2">Success</CardTitle>
          <CardDescription className="mb-12">
            Thank you for your payment. Enjoy Zirr AI!
          </CardDescription>

          <Button
            onClick={() => {
              navigate('/create');
            }}
          >
            Create a note
          </Button>
        </>
      ) : (
        <>
          <div className="relative mb-10 flex h-[70px] items-center justify-center">
            <div className="absolute flex size-[70px] items-center justify-center rounded-full bg-red-500 ">
              <RxCross2 className="text-5xl text-white" />
            </div>
          </div>
          <CardTitle className="mb-2">Error</CardTitle>
          <CardDescription className="mb-12">
            Payment has not been completed
          </CardDescription>
          <Button
            onClick={() => {
              navigate('/payment');
            }}
          >
            Go back to payment
          </Button>
        </>
      )}
    </div>
  );
};

export default PaymentAnswer;
