import React, { useState } from 'react';
import { Separator } from '../ui/Separator';
import { Button } from '../ui/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import RoutingPath from '../../routing/RoutingPath';
import Subscription from '../../payment/Subscription';
import { useMutation } from '@tanstack/react-query';
import PaymentApi from '../../api/PaymentApi';
import { useToast } from '../ui/Toast/UseToast';
import { useUserData } from '../../context/UserContextProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { Switch } from '../ui/Switch';

// interface PlanChangeProps {}

export default function PlanChange() {
  const { userId, userData } = useUserData();
  const { user, getAccessTokenSilently } = useAuth0();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isAnnual, setIsAnnual] = useState(false);
  const label = isAnnual ? 'Annual billing (Save 10%)' : 'Monthly billing';

  const time = isAnnual ? 'year' : 'month';
  const soloPrice = isAnnual ? '$1,069.20' : '$99';

  const mutation = useMutation({
    mutationFn: async (form: Subscription) => {
      try {
        if (!user?.sub) {
          throw new Error('No user id');
        }
        const token = await getAccessTokenSilently();
        if (!token) {
          throw new Error('No access token');
        }
        await new PaymentApi().startSubscription(user.sub, form, token);
        toast({
          title: 'Success',
          description: 'Subscription saved',
        });
      } catch (e) {
        toast({
          variant: 'destructive',
          title: 'Error',
          description: 'Please try again in few moments or contact support',
        });
        console.log(e);
      }
    },
  });

  const handleSelectFreePlan = () => {
    window.location.href = `https://billing.stripe.com/p/login/00g4h7cRWbqvcgw3cc?prefilled_email=${
      userData?.email || ''
    }`;
  };

  const handleSelectEnterprisePlan = () => {
    navigate(RoutingPath.CONTACT_SALES);
  };

  return (
    <>
      <h2 className="text-2xl font-bold">{t('button.changePlan')}</h2>
      <p className="text-sm text-gray-500">{t('payment.description')}</p>
      <Separator className="my-5" />
      <div className="">
        <div className="mx-auto max-w-7xl mb-6">
          <div className="mt-16 flex justify-center">
            <div className="flex items-center">
              <Switch
                checked={isAnnual}
                onCheckedChange={() => setIsAnnual(!isAnnual)}
              />
              <p className="m-0 ps-1">{label}</p>
            </div>
          </div>
        </div>
        <div className="relative z-10 mx-auto max-w-7xl">
          <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-7xl lg:grid-cols-3 lg:gap-8">
            <div className="flex flex-col rounded-xl bg-white dark:bg-gray-900 ring-1 ring-black/10">
              <div className="p-6">
                <div className="flex">
                  <h2 className="text-xl leading-6 font-bold text-slate-900">
                    Free
                  </h2>
                  <div className="items-center ml-2"></div>
                </div>
                <div>
                  <p className="mt-8">
                    <span className="text-4xl font-bold text-slate-900 tracking-tighter">
                      $0
                    </span>
                    <span className="text-base font-medium text-slate-500">
                      /{time}
                    </span>
                  </p>
                </div>
                <div className="mt-8">
                  <Button className="w-full" onClick={handleSelectFreePlan}>
                    {t('button.select')}
                  </Button>
                </div>
                <div className="mt-8">
                  <ul role="list" className="space-y-6">
                    <li className="flex items-start">
                      <div className="flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          className="h-6 w-6 text-teal-600 dark:text-teal-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          ></path>
                        </svg>
                      </div>
                      <p className="ml-3 text-sm leading-6 text-gray-600 dark:text-gray-300">
                        12 free sessions
                      </p>
                    </li>
                    <li className="flex items-start">
                      <div className="flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          className="h-6 w-6 text-teal-600 dark:text-teal-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          ></path>
                        </svg>
                      </div>
                      <p className="ml-3 text-sm leading-6 text-gray-600 dark:text-gray-300">
                        No credit card
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex flex-col rounded-xl bg-white dark:bg-gray-900 ring-1 ring-black/10">
              <div className="p-6">
                <div className="flex">
                  <h3 className="text-xl leading-6 font-bold text-slate-900">
                    Solo Pro
                  </h3>
                  {isAnnual ? (
                    <div className="items-center ml-2">
                      {/* eslint-disable-next-line tailwindcss/no-contradicting-classname */}
                      <p className="rounded-full text-white bg-teal-600 dark:bg-teal-400/10 px-2.5 py-1 text-xs font-semibold text-teal-600 dark:text-teal-400">
                        10% OFF
                      </p>
                    </div>
                  ) : null}
                </div>
                <div>
                  <p className="mt-8">
                    <span className="text-4xl font-bold text-slate-900 tracking-tighter">
                      {soloPrice}
                    </span>
                    <span className="text-base font-medium text-slate-500">
                      /{time}
                    </span>
                  </p>
                </div>
                <div className="mt-8">
                  {isAnnual ? (
                    <form
                      action={`/audioapi/payment/price_1P6XVTJPyPHT19joizp0cy7w?userId=${
                        user?.sub || ''
                      }`}
                      method="POST"
                      className="w-full"
                    >
                      <Button type="submit" className="w-full">
                        {t('button.select')}
                      </Button>
                    </form>
                  ) : (
                    <form
                      action={`/audioapi/payment/price_1P6XVUJPyPHT19johH6g3Gve?userId=${
                        user?.sub || ''
                      }`}
                      method="POST"
                      className="w-full mb-3"
                    >
                      <Button type="submit" className="w-full">
                        {t('button.select')}
                      </Button>
                    </form>
                  )}
                </div>
                <div className="mt-8">
                  <ul role="list" className="space-y-6">
                    <li className="flex items-start">
                      <div className="flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          className="h-6 w-6 text-teal-600 dark:text-teal-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          ></path>
                        </svg>
                      </div>
                      <p className="ml-3 text-sm leading-6 text-gray-600 dark:text-gray-300">
                        Unlimited sessions
                      </p>
                    </li>
                    <li className="flex items-start">
                      <div className="flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          className="h-6 w-6 text-teal-600 dark:text-teal-400"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          ></path>
                        </svg>
                      </div>
                      <p className="ml-3 text-sm leading-6 text-gray-600 dark:text-gray-300">
                        Cancel at any time
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex flex-col rounded-xl bg-gray-900 ring-1 ring-black/10">
              <div className="p-6">
                <div className="flex">
                  <h2 className="text-xl leading-6 font-bold text-white">
                    Enterprise
                  </h2>
                  <div className="items-center ml-2"></div>
                </div>
                <div>
                  <p className="mt-8">
                    <span className="text-4xl font-bold text-white tracking-tighter">
                      Custom
                    </span>
                  </p>
                </div>
                <div className="mt-8">
                  <Button
                    onClick={handleSelectEnterprisePlan}
                    className="w-full rounded-lg bg-gray-700 dark:bg-gray-700 px-4 py-2.5 text-center text-sm font-semibold leading-5 text-white shadow-md hover:bg-gray-800 dark:hover:bg-gray-800 cursor-pointer"
                  >
                    Contact sales
                  </Button>
                </div>
                <div className="mt-8">
                  <ul role="list" className="space-y-6">
                    <li className="flex items-start">
                      <div className="flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          className="h-6 w-6 text-white"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          ></path>
                        </svg>
                      </div>
                      <p className="ml-3 text-sm leading-6 text-gray-600 dark:text-gray-300">
                        Unlimited sessions
                      </p>
                    </li>
                    <li className="flex items-start">
                      <div className="flex-shrink-0">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                          className="h-6 w-6 text-white"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          ></path>
                        </svg>
                      </div>
                      <p className="ml-3 text-sm leading-6 text-gray-600 dark:text-gray-300">
                        Cancel at any time
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
