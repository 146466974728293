import React, { useState } from 'react';
import { useForm, SubmitHandler, SubmitErrorHandler } from 'react-hook-form';
import Page from '../Page/Page';
import { Card } from '../ui/Card/Card';
import { useTranslation } from 'react-i18next';
import { CardHeader } from '../ui/Card/CardHeader';
import { CardTitle } from '../ui/Card/CardTitle';
import { CardDescription } from '../ui/Card/CardDescription';
import { CardContent } from '../ui/Card/CardContent';
import LoginWithPhoneNumber from './LoginWithPhoneNumber';
import LoginWithEmail from './LoginWithEmail';
import LoginWayButtons from './LoginWayButtons';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../ui/Toast/UseToast';
import { useAuth0 } from '@auth0/auth0-react';
import { FaGoogle } from 'react-icons/fa';
import { Button } from '../ui/Button';

type LoginFormEmail = {
  login: string;
  password: string;
};

export default function LoginForm() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [login, setLogin] = useState('');

  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/',
      },
      authorizationParams: {
        prompt: 'login',
      },
    });
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<LoginFormEmail>();

  const onSubmit: SubmitHandler<LoginFormEmail> = async (data) => {
    // try {
    //   const response = await axios.post<UserLoginResponse>(
    //     `${HOST}/audioapi/login`,
    //     {
    //       username: data.login,
    //       password: data.password,
    //     }
    //   );
    //   const { userId, user } = response.data;
    //   if (!userId || !user) {
    //     throw new Error('No valid login response');
    //   }
    //   localStorage.setItem('userId', userId);
    //   setUser(user);
    //   setUserId(userId);
    //   // setIsAuthenticated(true);
    //   navigate(RoutingPath.CREATE);
    // } catch (err) {
    //   console.log(err);
    //   toast({
    //     variant: 'destructive',
    //     title: 'Error',
    //     description: 'Please try again in few moments or contact support',
    //   });
    // }
  };

  const onError: SubmitErrorHandler<LoginFormEmail> = (errors) => {
    console.error(errors);
  };
  const handleLoginTypeChange = (loginType: string) => {
    setLogin(loginType);
    reset();
  };

  return (
    <Card className="mx-auto max-w-[500px] md:min-w-[500px] w-full">
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <CardHeader>
          <CardTitle className="text-lg">{t('account.login')}</CardTitle>
          <CardDescription className="text-sm">
            {t('account.loginDescription')}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid w-full items-center gap-4">
            {/*<FormContentButtons*/}
            {/*  buttonLabel={t('account.google')}*/}
            {/*  separator={t('account.separator')}*/}
            {/*<div className="flex flex-row justify-between">*/}
            {/*  <Button*/}
            {/*    type="button"*/}
            {/*    variant="outline"*/}
            {/*    className="flex-1"*/}
            {/*    icon={<FaGoogle />}*/}
            {/*    iconSize="text-xl"*/}
            {/*    onClick={() => handleLogin()}*/}
            {/*  >*/}
            {/*    <p className="text-lg">{t('account.google')}</p>*/}
            {/*  </Button>*/}
            {/*</div>*/}
            {/*<div className="flex flex-col space-y-1.5">*/}
            {/*  <div className="relative my-4 flex w-full items-center justify-center border-t border-gray-200">*/}
            {/*    <p className="absolute z-10 bg-white p-3 text-center text-sm text-gray-400">*/}
            {/*      {t('account.separator')}*/}
            {/*    </p>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <LoginWayButtons
              onEmailClick={handleLogin}
              onNumberClick={() => handleLoginTypeChange('number')}
              loginType={login}
              firstLoginWay={t('account.loginEmail')}
              secondLoginWay={t('account.loginNumber')}
            />
            {/*{login === 'email' ? (*/}
            {/*  <LoginWithEmail register={register} errors={errors} />*/}
            {/*) : login === 'number' ? (*/}
            {/*  <LoginWithPhoneNumber register={register} errors={errors} />*/}
            {/*) : null}*/}
          </div>
        </CardContent>
      </form>
    </Card>
  );
}
