import axios from 'axios';
import { HOST } from './config';
import { Cdss } from '../result/Cdss';

export default class CdssApi {
  async edit(id: string, userId: string, note: Cdss, accessToken: string) {
    return axios.put<Cdss>(
      `${HOST}/audioapi/cdss/${id}?userId=${encodeURIComponent(userId)}`,
      {
        note,
      },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
}
