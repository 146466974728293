import React, { useMemo, useState } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table';
import { Table } from '../ui/Table/Table';
import { TableHeader } from '../ui/Table/TableHeader';
import { TableRow } from '../ui/Table/TableRow';
import { TableHead } from '../ui/Table/TableHead';
import { TableBody } from '../ui/Table/TableBody';
import { TableCell } from '../ui/Table/TableCell';
import { Button } from '../ui/Button';
import { useQuery } from '@tanstack/react-query';
import PatientsApi from '../../api/PatientsApi';
import Loading from '../Loading/Loading';
import Patient from '../../patient/Patient';
import FindAllPatientsResponse from '../../patient/FindAllPatientsResponse';
import { useNavigate } from 'react-router-dom';
import { useUserData } from '../../context/UserContextProvider';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast } from '../ui/Toast/UseToast';
import { format } from 'date-fns';

const columns: ColumnDef<Patient>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: ({ row }) => <div>{row.getValue('name')}</div>,
  },
  {
    accessorKey: 'lastUpdated',
    header: 'Last Updated',
    cell: ({ row }) => {
      const updatedAt = row.getValue('lastUpdated') as string;
      if (updatedAt) {
        const formattedDate = format(
          new Date(updatedAt),
          'dd-MM-yyyy HH:mm:ss'
        );
        return <div>{formattedDate}</div>;
      }
      return <div>{'-'}</div>;
    },
  },
];

export default function PatientsTable() {
  const { userId } = useUserData();
  const { user, getAccessTokenSilently } = useAuth0();
  const { toast } = useToast();
  const navigate = useNavigate();
  const [rowSelection, setRowSelection] = useState({});

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, error, isLoading, isFetching, refetch } = useQuery<
    FindAllPatientsResponse | undefined,
    Error
  >({
    queryKey: ['patientsList', pageIndex],
    queryFn: async () => {
      try {
        if (!user?.sub) {
          throw new Error('No user id');
        }
        const token = await getAccessTokenSilently();
        if (!token) {
          throw new Error('No access token');
        }
        return new PatientsApi().getAll(user.sub, token, pageIndex + 1);
      } catch (e) {
        toast({
          variant: 'destructive',
          title: 'Could not find patients',
          description: 'Please try again in few moments or contact support',
        });
        console.log(e);
      }
    },
    retry: false,
    cacheTime: 0,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: data?.results || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    state: {
      rowSelection,
      pagination,
    },
    manualPagination: true,
    pageCount: data?.pages,
  });

  const handleNavigate = (patient: Patient) => {
    navigate(`/patient/${patient._id}`, { state: patient });
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="w-full">
      <h2 className="mb-5 text-lg font-bold">All patients</h2>
      {/* Add your filter and column selection UI here */}
      <div className="rounded-md border">
        <Table>
          {/* Render table header */}
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      className="text-sm font-semibold text-black"
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          {/* Render table body */}
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  className="cursor-pointer"
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  onClick={() => handleNavigate(row.original as Patient)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 p-4">
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            table.previousPage();
          }}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => {
            table.nextPage();
          }}
          disabled={!table.getCanNextPage()}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
