import { PreprocessingDialogMessage } from '../component/Note/RenderNote';
import axios from 'axios';
import { HOST } from './config';

export interface EditDialog {
  editDialog?: PreprocessingDialogMessage[];
}

export default class DialogApi {
  async edit(
    id: string,
    userId: string,
    editDialog: PreprocessingDialogMessage[],
    accessToken: string
  ) {
    return axios.put(
      `${HOST}/audioapi/dialog/${id}?userId=${encodeURIComponent(userId)}`,
      {
        editDialog,
      },
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
}
