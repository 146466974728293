import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardHeader } from '../ui/Card/CardHeader';
import { CardTitle } from '../ui/Card/CardTitle';
import { CardDescription } from '../ui/Card/CardDescription';
import { CardContent } from '../ui/Card/CardContent';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { InputWithLabel } from '../ReusableElements/InputWithLabel';
import ResizableTextareaField from '../Form/ResizableTextareaField';
import { format } from 'date-fns';
import MessageApi from '../../api/MessageApi';
import { useToast } from '../ui/Toast/UseToast';
import { Button } from '../ui/Button';
import { CardFooter } from '../ui/Card/CardFooter';
import { useUserData } from '../../context/UserContextProvider';
import { useAuth0 } from '@auth0/auth0-react';

type SupportFormField = {
  title: string;
  description: string;
};

const schema = z.object({
  title: z
    .string()
    .min(1, { message: 'You need to pass title of the message' }),
  description: z
    .string()
    .min(1, { message: 'You need to describe your problem for us' }),
});

const SupportForm = ({ header, team }: { header: string; team: string }) => {
  const { userId } = useUserData();
  const { user, getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const { toast } = useToast();

  const {
    handleSubmit,
    register,
    control,
    setError,
    formState: { errors },
  } = useForm<SupportFormField>({
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<SupportFormField> = async (data) => {
    try {
      const currentDate = new Date();
      const formattedDate = format(currentDate, 'yyyy-MM-dd');
      const formattedTime = format(currentDate, 'HH:mm');

      await schema.parseAsync(data);

      const message = {
        ...data,
        sentTime: formattedDate + ' ' + formattedTime,
      };
      if (!user?.sub) {
        throw new Error('No user id');
      }
      const token = await getAccessTokenSilently();
      if (!token) {
        throw new Error('No access token');
      }
      await new MessageApi().send(
        user.sub,
        {
          title: message.title,
          description: message.description,
          sentTime: message.sentTime,
        },
        token
      );
      toast({
        title: 'Success',
        description: 'Message sent',
      });
    } catch (errors) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Please try again in few moments or contact support',
      });
      console.error(errors);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
    >
      <CardHeader>
        <CardTitle className="text-lg font-bold">{header}</CardTitle>
        <CardDescription>
          {t('support.supportMsg1')} <span>{team} </span>
          {t('support.supportMsg2')}
        </CardDescription>
      </CardHeader>
      <CardContent className="pb-0">
        <div className="grid w-full items-center ">
          <InputWithLabel
            placeholder={t('support.title')}
            register={register}
            registerName="title"
            errors={errors}
            id="title"
            label={t('support.title')}
          />
          <Controller
            name="description"
            control={control}
            defaultValue=""
            rules={{ required: 'You need to describe your problem for us' }}
            render={({ field }) => (
              <>
                <ResizableTextareaField
                  {...field}
                  placeholder={t('support.descriptionMsg')}
                  label={t('support.description')}
                  onChange={(text) => {
                    field.onChange(text);
                  }}
                />
                {errors.description ? (
                  <p className="mb-2 text-xs text-red-500">
                    {errors.description.message}
                  </p>
                ) : null}
              </>
            )}
          />
        </div>
      </CardContent>
      <CardFooter className="flex justify-between">
        <Button className="w-full" type="submit">
          {t('support.send')}
        </Button>
      </CardFooter>
    </form>
  );
};

export default SupportForm;
