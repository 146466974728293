export type RoutingPathKey =
  | 'create'
  | 'saved'
  | 'patients'
  | 'transcription'
  | 'account'
  | 'editAccount'
  | 'payment'
  | 'support'
  | 'privacy'
  | 'record'
  | 'login'
  | 'addpatient'
  | 'paymentSuccess'
  | 'paymentFailed'
  | 'contactSales'
  | 'changePlan';

export default class RoutingPath {
  static CREATE = '/create';
  static SAVED = '/saved';
  static ACCOUNT = '/account';
  static EDIT_ACCOUNT = '/edit-account';
  static PAYMENT = '/payment';
  static SUPPORT = '/support';
  static PATIENTS = '/patients';
  static TRANSCRIPTION = '/language';
  static PRIVACY = '/user-privacy';
  static RECORD = '/record';
  static LOGIN = '/login';
  static ADDPATIENT = '/add-patient';
  static PAYMENT_SUCCESS = '/payment-success';
  static PAYMENT_FAILED = '/payment-failed';
  static CONTACT_SALES = '/contact-sales';
  static CHANGE_PLAN = '/change-plan';
  static VERIFY_EMAIL = '/verify-email';

  private static MAP = {
    create: RoutingPath.CREATE,
    saved: RoutingPath.SAVED,
    account: RoutingPath.ACCOUNT,
    editAccount: RoutingPath.EDIT_ACCOUNT,
    payment: RoutingPath.PAYMENT,
    support: RoutingPath.SUPPORT,
    patients: RoutingPath.PATIENTS,
    transcription: RoutingPath.TRANSCRIPTION,
    privacy: RoutingPath.PRIVACY,
    record: RoutingPath.RECORD,
    login: RoutingPath.LOGIN,
    addpatient: RoutingPath.ADDPATIENT,
    paymentSuccess: RoutingPath.PAYMENT_SUCCESS,
    paymentFailed: RoutingPath.PAYMENT_FAILED,
    contactSales: RoutingPath.CONTACT_SALES,
    changePlan: RoutingPath.CHANGE_PLAN,
  };

  static get(name: RoutingPathKey): RoutingPath {
    return this.MAP[name];
  }

  static getAll() {
    return [
      this.CREATE,
      this.SAVED,
      this.ACCOUNT,
      this.PAYMENT,
      this.SUPPORT,
      this.CONTACT_SALES,
      this.PATIENTS,
      this.TRANSCRIPTION,
      this.PRIVACY,
      this.RECORD,
    ];
  }
}
