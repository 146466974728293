import React from 'react';

import { RxCross2 } from 'react-icons/rx';
import { CardTitle } from '../ui/Card/CardTitle';
import { CardDescription } from '../ui/Card/CardDescription';
import { Button } from '../ui/Button';
import { useNavigate } from 'react-router-dom';

export default function VerifyEmailPage() {
  const navigate = useNavigate();
  return (
    <div className="block">
      <div className="bg-background">
        <div className="container min-h-lvh">
          <div className="mx-auto max-w-[500px] w-full flex min-h-lvh items-center justify-center pb-3 pt-3">
            <div>
              <div className="w-full">
                <div className="relative mb-10 flex h-[70px] items-center justify-center">
                  <div className="absolute flex size-[70px] items-center justify-center rounded-full bg-red-500 ">
                    <RxCross2 className="text-5xl text-white" />
                  </div>
                </div>
              </div>
              <div className="w-full">
                <CardTitle className="text-center mb-2">Error</CardTitle>
              </div>
              <div className="w-full">
                <CardDescription className="text-center mb-12">
                  Please verify your email before login to Zirr AI
                </CardDescription>
              </div>
              <div className="w-full">
                <Button
                  className="w-full text-center"
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  Go back to login
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
