import React from 'react';
import Page from '../Page/Page';
import { Button } from '../ui/Button';
import { useTranslation } from 'react-i18next';
import { CardTitle } from '../ui/Card/CardTitle';
import { CardDescription } from '../ui/Card/CardDescription';
import { Separator } from '../ui/Separator';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Card } from '../ui/Card/Card';
import { CardHeader } from '../ui/Card/CardHeader';
import { CardContent } from '../ui/Card/CardContent';
import { InputWithLabel } from '../ReusableElements/InputWithLabel';
import PatientsApi from '../../api/PatientsApi';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import RoutingPath from '../../routing/RoutingPath';
import { useToast } from '../ui/Toast/UseToast';
import { useAuth0 } from '@auth0/auth0-react';

type AddPatientFields = {
  name: string;
  lastUpdated: string;
};

const schema = z.object({
  name: z.string().min(1, {
    message: 'You need to pass patient name.',
  }),
});

interface AddPatientProbs {
  onClick?: () => void;
}
export default function AddPatient({ onClick }: AddPatientProbs) {
  const { user, getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toast } = useToast();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<AddPatientFields>({
    resolver: zodResolver(schema),
  });

  const onSubmit: SubmitHandler<AddPatientFields> = async (data) => {
    try {
      const currentDate = new Date();
      const formattedDate = format(currentDate, 'yyyy-MM-dd');
      const formattedTime = format(currentDate, 'HH:mm');

      await schema.parseAsync(data);
      const patientData = {
        ...data,
        lastUpdated: formattedDate + ' ' + formattedTime,
      };
      if (!user?.sub) {
        throw new Error('No user id');
      }
      const token = await getAccessTokenSilently();
      if (!token) {
        throw new Error('No access token');
      }
      await new PatientsApi().addPatient(
        user.sub,
        {
          name: patientData.name,
          lastUpdated: patientData.lastUpdated,
        },
        token
      );
      toast({
        title: 'Success',
        description: 'Patient added',
      });
      navigate(RoutingPath.CREATE);
    } catch (errors) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Please try again in few moments or contact support',
      });
      console.error(errors);
    }
  };

  return (
    <Page>
      <Card className="mx-auto max-w-[800px] w-full">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit)(e);
          }}
        >
          <CardHeader>
            <CardTitle className="text-lg font-bold">
              {t('patient.addPatient')}
            </CardTitle>
            <CardDescription className="mt-1 text-sm">
              {t('patient.addPatientMsg')}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Separator className="mb-5" />
            <InputWithLabel
              register={register}
              errors={errors}
              registerName="name"
              placeholder={t('patient.name')}
              id="name"
              label={t('patient.name')}
            />
            <Button className="my-5 w-full" type="submit">
              {t('note.addPatient')}
            </Button>
            <Button
              className="w-full"
              variant="outline"
              onClick={() => {
                navigate(RoutingPath.CREATE);
              }}
            >
              {t('note.return')}
            </Button>
          </CardContent>
        </form>
      </Card>
    </Page>
  );
}
