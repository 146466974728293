import React from 'react';
import { Tabs } from '../ui/Tabs/Tabs';
import { TabsList } from '../ui/Tabs/TabsList';
import { TabsTrigger } from '../ui/Tabs/TabsTrigger';
import { TabsContent } from '../ui/Tabs/TabsContent';
import Transcription from './Transcription';
import SOAPNotesList from './SOAPNotesList';
import {
  DeepgramResponse,
  PreprocessingDialogMessage,
  SOAPNote,
} from './RenderNote';
import SoapNoteDetails from './SoapNoteDetails';
import { useLocation, useParams } from 'react-router-dom';
import DialogApi from '../../api/DialogApi';
import NotesApi from '../../api/NotesApi';
import { useToast } from '../ui/Toast/UseToast';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserData } from '../../context/UserContextProvider';
import CDSS from './CDSS';
import { Cdss } from '../../result/Cdss';
import CdssApi from '../../api/CdssApi';

const SoapNoteDetailsTab = () => {
  const { userId } = useUserData();
  const { user, getAccessTokenSilently } = useAuth0();
  const { toast } = useToast();
  const { resultId } = useParams();
  const location = useLocation();
  const data = location.state as DeepgramResponse;

  if (!resultId || !Object.keys(data).length) {
    return <h2>No note provided</h2>;
  }

  const handleTranscriptionEdit = async (
    dialog: PreprocessingDialogMessage[]
  ) => {
    try {
      if (!user?.sub) {
        throw new Error('No user id');
      }
      const token = await getAccessTokenSilently();
      if (!token) {
        throw new Error('No access token');
      }
      await new DialogApi().edit(resultId, user.sub, dialog, token);
      toast({
        title: 'Success',
        description: 'Transcription saved',
      });
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Please try again in few moments or contact support',
      });
      console.log(e);
    }
  };

  const handleNoteEdit = async (note: SOAPNote) => {
    try {
      if (!user?.sub) {
        throw new Error('No user id');
      }
      const token = await getAccessTokenSilently();
      if (!token) {
        throw new Error('No access token');
      }
      await new NotesApi().edit(resultId, user.sub, note, token);
      toast({
        title: 'Success',
        description: 'SOAP note saved',
      });
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Please try again in few moments or contact support',
      });
      console.log(e);
    }
  };

  const handleCdssEdit = async (cdss: Cdss) => {
    try {
      if (!user?.sub) {
        throw new Error('No user id');
      }
      const token = await getAccessTokenSilently();
      if (!token) {
        throw new Error('No access token');
      }
      await new CdssApi().edit(resultId, user.sub, cdss, token);
      toast({
        title: 'Success',
        description: 'CDSS saved',
      });
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Please try again in few moments or contact support',
      });
      console.log(e);
    }
  };

  return (
    <>
      <Tabs defaultValue="soapNotesInfo" className="">
        <TabsList className="hidden lg:grid mb-5 grid-cols-4 bg-primary-background">
          <TabsTrigger value="soapNotesInfo">Info</TabsTrigger>
          <TabsTrigger value="transcription">Transcription</TabsTrigger>
          <TabsTrigger value="soapNotes">SOAP Note</TabsTrigger>
          <TabsTrigger value="cdss">
            Clinical decision support system
          </TabsTrigger>
        </TabsList>
        <div className="lg:hidden mb-5 flex">
          <TabsList className="flex h-auto flex-wrap items-center justify-start bg-primary-background">
            <TabsTrigger value="soapNotesInfo">Info</TabsTrigger>
            <TabsTrigger value="transcription">Transcription</TabsTrigger>
            <TabsTrigger value="soapNotes">SOAP Note</TabsTrigger>
            <TabsTrigger value="cdss">CDSS</TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="soapNotesInfo">
          <SoapNoteDetails resultId={resultId} result={data} />
        </TabsContent>
        <TabsContent value="transcription">
          <Transcription
            id={data?._id}
            dialog={data?.editDialog || data?.preprocessingDialog}
            speaker0={data?.speaker0}
            speaker1={data?.speaker1}
            updatedAt={data?.updatedAt}
            onClick={handleTranscriptionEdit}
          />
        </TabsContent>
        <TabsContent value="soapNotes">
          <SOAPNotesList
            id={data?._id}
            note={data?.note}
            updatedAt={data?.updatedAt}
            onClick={handleNoteEdit}
          />
        </TabsContent>
        <TabsContent value="cdss">
          {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
          <CDSS cdss={data?.cdss} onClick={handleCdssEdit} />
        </TabsContent>
      </Tabs>
      <div className="md:hidden"></div>
    </>
  );
};

export default SoapNoteDetailsTab;
