import { DeepgramResponse, SOAPNote } from '../component/Note/RenderNote';
import axios from 'axios';
import { HOST } from './config';
import FindAllResultsResponse from '../result/FindAllResultsResponse';

export interface EditSOAPNote {
  note?: SOAPNote;
}

export default class ResultApi {
  async getById(id: string, userId: string, accessToken: string) {
    const response = await axios.get<DeepgramResponse>(
      `${HOST}/audioapi/result/${id}?userId=${encodeURIComponent(userId)}`,
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }

  async getAll(userId: string, accessToken: string, page = 1, keyword = '') {
    const response = await axios.get<FindAllResultsResponse>(
      `${HOST}/audioapi/results?userId=${encodeURIComponent(
        userId
      )}&page=${page}${keyword ? `&keyword=${keyword}` : ''}`,
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }

  async postAudio(userId: string, form: FormData, accessToken: string) {
    const response = await axios.post(
      `${HOST}/audioapi/audio?userId=${encodeURIComponent(userId)}`,
      form,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }
}
