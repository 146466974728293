import React, { useState } from 'react';
import Page from '../Page/Page';
import { CardTitle } from '../ui/Card/CardTitle';
import { CardDescription } from '../ui/Card/CardDescription';
import { CgTranscript } from 'react-icons/cg';

import { useTranslation } from 'react-i18next';
import { Button } from '../ui/Button';
import { RiVoiceprintLine } from 'react-icons/ri';

interface TranscribingProcessProps {
  message?: string;
  onClick?: () => void;
}

const TranscribingProcess = ({
  message,
  onClick,
}: TranscribingProcessProps) => {
  const { t } = useTranslation();
  const [recording, setRecording] = useState(true);
  return (
    <div className="flex h-[500px] flex-col items-center justify-center text-center">
      <CardTitle className="mb-2">{t('note.transcribeInfo')}</CardTitle>
      <CardDescription className="mb-12">
        {t('note.transcribeDoneInfo')}
      </CardDescription>

      <div className="relative mt-10 flex h-[70px] items-center justify-center ">
        <div
          className={`absolute flex size-[70px] items-center justify-center rounded-full bg-primary ${
            recording ? 'wave-effect' : ''
          }`}
        ></div>
        <div
          className="absolute flex size-[70px] items-center justify-center rounded-full bg-primary "
          onClick={onClick}
        >
          <CgTranscript className="text-4xl text-white" />
        </div>
      </div>
      {message ? (
        <Button
          onClick={onClick}
          className=" mt-5 w-full"
          icon={<RiVoiceprintLine />}
          iconSize="text-xl"
        >
          {t('note.transcriptionSave')}
        </Button>
      ) : null}
    </div>
  );
};

export default TranscribingProcess;
