import React from 'react';
import { Button } from '../ui/Button';

type LoginWayButtonsProps = {
  onEmailClick: () => void;
  onNumberClick: () => void;
  loginType: string;
  firstLoginWay: string;
  secondLoginWay: string;
};

const LoginWayButtons: React.FC<LoginWayButtonsProps> = ({
  onEmailClick,
  onNumberClick,
  firstLoginWay,
  secondLoginWay,
}) => {
  return (
    <div className="flex flex-col">
      <Button className="bg-primary" type="button" onClick={onEmailClick}>
        <p className="text-base">{firstLoginWay}</p>
      </Button>
      {/*<Button*/}
      {/*  className=""*/}
      {/*  variant="outline"*/}
      {/*  type="button"*/}
      {/*  onClick={onNumberClick}*/}
      {/*>*/}
      {/*  <p className="text-base">{secondLoginWay}</p>*/}
      {/*</Button>*/}
    </div>
  );
};
export default LoginWayButtons;
