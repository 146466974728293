import { Buffer } from 'buffer';
import * as process from 'process';

if (typeof window !== 'undefined' && typeof window.Buffer === 'undefined') {
  // Assign Buffer to the global window object
  window.Buffer = Buffer;
}
if (typeof window !== 'undefined' && typeof window.process === 'undefined') {
  // Assign Buffer to the global window object
  window.process = process;
}
