import React from 'react';

interface MobileNavBarBrandProps {
  handleClick: () => void;
}

export const MobileNavBarBrand: React.FC<MobileNavBarBrandProps> = ({
  handleClick,
}) => {
  return (
    <div onClick={handleClick} className="mobile-nav-bar__brand">
      <a style={{ width: '64px' }} href="https://zirr.ai">
        <svg
          style={{ maxWidth: '100%', maxHeight: '40px' }}
          width="2662"
          height="1149"
          viewBox="0 0 2662 1149"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M465 520C453 533.333 436.667 551.333 416 574C396 596.667 374.333 621.667 351 649C327.667 676.333 304 705 280 735C256.667 765 235.333 794 216 822H470V946H35V858C49 834 65.6667 808.333 85 781C105 753 125.667 725 147 697C169 669 190.667 642 212 616C233.333 589.333 253 565.333 271 544H47V420H465V520ZM719 946H570V420H719V946ZM733 268C733 295.333 724 317 706 333C688.667 348.333 668 356 644 356C620 356 599 348.333 581 333C563.667 317 555 295.333 555 268C555 240.667 563.667 219.333 581 204C599 188 620 180 644 180C668 180 688.667 188 706 204C724 219.333 733 240.667 733 268ZM1176.06 552C1162.73 548.667 1147.06 545.333 1129.06 542C1111.06 538 1091.73 536 1071.06 536C1061.73 536 1050.4 537 1037.06 539C1024.4 540.333 1014.73 542 1008.06 544V946H859.063V448C885.729 438.667 917.063 430 953.063 422C989.729 413.333 1030.4 409 1075.06 409C1083.06 409 1092.73 409.667 1104.06 411C1115.4 411.667 1126.73 413 1138.06 415C1149.4 416.333 1160.73 418.333 1172.06 421C1183.4 423 1193.06 425.667 1201.06 429L1176.06 552ZM1597.94 552C1584.6 548.667 1568.94 545.333 1550.94 542C1532.94 538 1513.6 536 1492.94 536C1483.6 536 1472.27 537 1458.94 539C1446.27 540.333 1436.6 542 1429.94 544V946H1280.94V448C1307.6 438.667 1338.94 430 1374.94 422C1411.6 413.333 1452.27 409 1496.94 409C1504.94 409 1514.6 409.667 1525.94 411C1537.27 411.667 1548.6 413 1559.94 415C1571.27 416.333 1582.6 418.333 1593.94 421C1605.27 423 1614.94 425.667 1622.94 429L1597.94 552Z"
            fill="#246D69"
          />
          <path
            d="M1789.24 867C1789.24 897 1779.58 920 1760.24 936C1741.58 951.333 1720.24 959 1696.24 959C1672.24 959 1650.58 951.333 1631.24 936C1612.58 920 1603.24 897 1603.24 867C1603.24 837 1612.58 814.333 1631.24 799C1650.58 783 1672.24 775 1696.24 775C1720.24 775 1741.58 783 1760.24 799C1779.58 814.333 1789.24 837 1789.24 867Z"
            fill="#91A1AF"
          />
          <path
            d="M2091 842C2105.67 842 2119.67 841.667 2133 841C2146.33 840.333 2157 839.333 2165 838V725C2159 723.667 2150 722.333 2138 721C2126 719.667 2115 719 2105 719C2091 719 2077.67 720 2065 722C2053 723.333 2042.33 726.333 2033 731C2023.67 735.667 2016.33 742 2011 750C2005.67 758 2003 768 2003 780C2003 803.333 2010.67 819.667 2026 829C2042 837.667 2063.67 842 2091 842ZM2079 406C2123 406 2159.67 411 2189 421C2218.33 431 2241.67 445.333 2259 464C2277 482.667 2289.67 505.333 2297 532C2304.33 558.667 2308 588.333 2308 621V931C2286.67 935.667 2257 941 2219 947C2181 953.667 2135 957 2081 957C2047 957 2016 954 1988 948C1960.67 942 1937 932.333 1917 919C1897 905 1881.67 887 1871 865C1860.33 843 1855 816 1855 784C1855 753.333 1861 727.333 1873 706C1885.67 684.667 1902.33 667.667 1923 655C1943.67 642.333 1967.33 633.333 1994 628C2020.67 622 2048.33 619 2077 619C2096.33 619 2113.33 620 2128 622C2143.33 623.333 2155.67 625.333 2165 628V614C2165 588.667 2157.33 568.333 2142 553C2126.67 537.667 2100 530 2062 530C2036.67 530 2011.67 532 1987 536C1962.33 539.333 1941 544.333 1923 551L1904 431C1912.67 428.333 1923.33 425.667 1936 423C1949.33 419.667 1963.67 417 1979 415C1994.33 412.333 2010.33 410.333 2027 409C2044.33 407 2061.67 406 2079 406ZM2591.73 946H2442.73V420H2591.73V946ZM2605.73 268C2605.73 295.333 2596.73 317 2578.73 333C2561.4 348.333 2540.73 356 2516.73 356C2492.73 356 2471.73 348.333 2453.73 333C2436.4 317 2427.73 295.333 2427.73 268C2427.73 240.667 2436.4 219.333 2453.73 204C2471.73 188 2492.73 180 2516.73 180C2540.73 180 2561.4 188 2578.73 204C2596.73 219.333 2605.73 240.667 2605.73 268Z"
            fill="#91A1AF"
          />
        </svg>
      </a>
    </div>
  );
};
